import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Col,
  Row,
  message,
  Select,
  InputNumber,
} from "antd";
import { API_METHODS } from "../../../constants";
import useApi from "../../../hooks/useApi";
import { getClientConfig } from "../../../generalFunctions";

function UpdateSite(props) {
  const { TextArea } = Input;
  const { vendor, customer, site_types } = getClientConfig();
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;

  const [editResponse, editRequest] = useApi(
    `/config/site/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Site updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        editRequest(values);
      }
    });
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title="Update Site"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="siteId"
              label="Site Id"
              rules={[{ required: true, message: "Site Id is required" }]}
            >
              <Input placeholder="Site Id" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="atmId"
              label="ATM Id"
              rules={[{ required: true, message: "ATM Id is required" }]}
            >
              <Input placeholder="ATM Id" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="siteTypeId"
              label="Site Type"
              rules={[{ required: true, message: "Site Type is required" }]}
            >
              <Select
                placeholder="Select Site Type"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {site_types.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="mspId"
              label="MSP"
              rules={[{ required: true, message: "MSP is required" }]}
            >
              <Select
                placeholder="Select MSP"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {vendor.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.vendorName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="bankId"
              label="Bank"
              rules={[{ required: true, message: "Bank is required" }]}
            >
              <Select
                placeholder="Select Roaming Attendant"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {customer.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.customerName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="formTypes"
              label="Person"
              rules={[{ required: true, message: "Person is required" }]}
            >
              <Input placeholder="Person" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="region"
              label="Region"
              rules={[{ required: true, message: "Region is required" }]}
            >
              <Input placeholder="Region" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: "State is required" }]}
            >
              <Input placeholder="State" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, message: "City is required" }]}
            >
              <Input placeholder="City" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: "Address is required" }]}
            >
              <TextArea placeholder="Address" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="pincode"
              label="Pincode"
              rules={[{ required: true, message: "Pincode is required" }]}
            >
              <Input placeholder="Pincode" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="raVisitCount"
              label="RA Visit Count"
              rules={[
                { required: true, message: "RA Visit Count is required" },
              ]}
            >
              <InputNumber
                placeholder="RA Visit Count"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default UpdateSite;
