import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Image, Input, InputNumber, List, Radio, Row, Select, Space, Upload, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { handleImageUpload, isEmpty } from '../../../generalFunctions';
import useApi from '../../../hooks/useApi';
import { API_METHODS } from '../../../constants';
import { UploadOutlined } from "@ant-design/icons";

const MISAddTakeoverForm = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [formId, setFormId] = useState();
    const [currentQuestions, setCurrentQuestions] = useState();
    const [dummyState, setDummyState] = useState();
    const [extraAssets, setExtraAssets] = useState([{}]);
    const [startTakeoverFormFillingResponseData, startTakeoverFormFillingRequest] = useApi(
        `/mis/takeover-form/start-form`,
        null,
        API_METHODS.POST
    );

    const [addTakeoverFormResponseData, addTakeoverFormRequest] = useApi(
        `/mis/takeover-form/submit-form`,
        null,
        API_METHODS.POST,
        true
    );
    const [compressedImageFiles, setCompressedImageFiles] = useState([]);
    const [siteList, setSiteList] = useState();
    const [sites, getSites] = useApi(
        `/mis/takeover-form/sites`,
        null,
        API_METHODS.GET
    );

    const [raList, setRAList] = useState();
    const [ra, getRA] = useApi(`/mis/common/ra`, null, API_METHODS.GET);

    const raSelected = (ra_id) => {
        getSites(null, API_METHODS.GET, `/mis/takeover-form/sites?raId=${ra_id}`);
    };

    const raCleared = (ra_id) => {
        getSites();
    };


    useEffect(() => {
        if (sites.data && !sites.isLoading && isEmpty(sites.error)) {
            setSiteList(sites.data);
        } else if (sites.error && !sites.isLoading) {
            message.info(sites.error);
        }
    }, [sites]);

    useEffect(() => {
        if (ra.data && !ra.isLoading && isEmpty(ra.error)) {
            setRAList(ra.data);
        } else if (ra.error && !ra.isLoading) {
            message.info(ra.error);
        }
    }, [ra]);


    const startTakeoverFormFilling = () => {
        form.validateFields().then((values) => {
            startTakeoverFormFillingRequest({ dbTableSiteId: values.dbTableSiteId });
        });
    }

    const addSiteTakeoverForm = () => {
        form.validateFields().then(async (values, fileList) => {
            let newValues = { ...values };
            let newAnswers = [];
            let extra_assets = [];

            currentQuestions.forEach(item => {
                let answerData = {
                    "quantity": values[item.questionId + "quantity"],
                    "remarks": values[item.questionId + "remarks"],
                    "questionId": item.questionId
                }
                delete newValues[item.questionId + "quantity"];
                delete newValues[item.questionId + "remarks"];

                let subAnswerKey = Object.keys(values).filter(key => (key === item.questionId + "sub1" || key === item.questionId + "sub2"));
                if (subAnswerKey.length > 0) {
                    answerData.answers = [];
                    subAnswerKey.forEach(key => {
                        const sub = {
                            id: key.toString()[key.length - 1],
                            ans: values[key]
                        }
                        answerData.answers.push(sub);
                        delete newValues[key];
                    })
                }
                newAnswers.push(answerData);
            });

            let formData = new FormData();
            for (const name in values) {
                if (name === "previous_employee_signature") {
                    if (values["previous_employee_signature"] !== undefined) {
                        const compressedFile = await handleImageUpload(values["previous_employee_signature"].file.originFileObj);
                        formData.append(
                            "previous_employee_signature",
                            compressedFile,
                            values["previous_employee_signature"].file.name
                        );
                        delete newValues["previous_employee_signature"];
                    }
                }

                if (name === "new_employee_signature") {
                    if (values["new_employee_signature"] !== undefined) {
                        const compressedFile = await handleImageUpload(values["new_employee_signature"].file.originFileObj);
                        formData.append(
                            "new_employee_signature",
                            compressedFile,
                            values["new_employee_signature"].file.name
                        );
                        delete newValues["new_employee_signature"];
                    }
                }

                if (name === "previous_employee_selfie") {
                    if (values["previous_employee_selfie"] !== undefined) {
                        const compressedFile = await handleImageUpload(values["previous_employee_selfie"].file.originFileObj);
                        formData.append(
                            "previous_employee_selfie",
                            compressedFile,
                            values["previous_employee_selfie"].file.name
                        );
                        delete newValues["previous_employee_selfie"];
                    }
                }

                if (name === "new_employee_selfie") {
                    if (values["new_employee_selfie"] !== undefined) {
                        const compressedFile = await handleImageUpload(values["new_employee_selfie"].file.originFileObj);
                        formData.append(
                          "new_employee_selfie",
                          compressedFile,
                          values["new_employee_selfie"].file.name
                        );
                        delete newValues["new_employee_selfie"];
                    }
                }
            }
            formData.append("answers", JSON.stringify(newAnswers));
            formData.append("id", JSON.stringify(formId));

            let extraAssetsList = extraAssets.filter(e => { if (Object.keys(e).length !== 0) return e });
            if (extraAssetsList.length > 0) {
                formData.append("extra_asset", JSON.stringify(extraAssetsList));
            }

            Object.keys(newValues).forEach(key => {
                if (key.includes("extra_asset")) {
                    delete newValues[key];
                }
            });

            Object.keys(newValues).forEach(key => {
                formData.append(key, newValues[key]);
            });
            // newValues.answers = newAnswers;
            // newValues.extra_asset = extraAssetsList;
            addTakeoverFormRequest(formData);
        }).catch(e => console.log(e));
    }

    const handleAnswerChange = (e) => {
        setDummyState(e);
    }

    const addExtraAsset = (index) => {
        form.validateFields().then(values => {
            if (values["extra_asset_name" + index]) {
                const asset = {
                    id: index + 1,
                    assestname: values["extra_asset_name" + index],
                    answers: [{
                        id: 1,
                        ans: values["extra_asset" + index + "" + 1]
                    },
                    {
                        id: 2,
                        ans: values["extra_asset" + index + "" + 2]
                    }],
                    // quantity: values["extra_asset_quantity" + index],
                    remarks: values["extra_asset_remarks" + index]
                }
                const isPresent = extraAssets.find(a => a.id === asset.id);
                if (!isPresent) {
                    let assets = [...extraAssets];
                    assets.push(asset);
                    setExtraAssets(assets);
                }
            }
        }).catch(e => e);
    }

    const uploadProps = {
        beforeUpload: (file) => {
            const isImage = file.type === "image/png" || file.type === "image/jpeg";
            if (!isImage) {
                message.error(`${file.name} error. Only PNG and JPEG files supported`);
            }
            return isImage || Upload.LIST_IGNORE;
        },
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    useEffect(() => {
        getSites();
        getRA();
    }, []);

    useEffect(() => {
        if (startTakeoverFormFillingResponseData.data && !startTakeoverFormFillingResponseData.isLoading && isEmpty(startTakeoverFormFillingResponseData.error)) {
            if (startTakeoverFormFillingResponseData.data) {
                const { id, questions, site_type, address } = startTakeoverFormFillingResponseData.data;
                setFormId(id);
                setCurrentQuestions(questions);
                const site_id = siteList.find(s => s.id === form.getFieldValue("dbTableSiteId"))?.site_id;
                form.setFieldsValue({ site_type, address, site_id });
            }
        } else if (startTakeoverFormFillingResponseData.error && !startTakeoverFormFillingResponseData.isLoading) {
            message.info(startTakeoverFormFillingResponseData.error);
        }
    }, [startTakeoverFormFillingResponseData]);

    useEffect(() => {
        if (addTakeoverFormResponseData.data && !addTakeoverFormResponseData.isLoading) {
            message.info("Site takeover details saved successfully.");
            navigate(-1);
        } else if (addTakeoverFormResponseData.error && !addTakeoverFormResponseData.isLoading) {
            message.info(addTakeoverFormResponseData.error);
        }
    }, [addTakeoverFormResponseData]);

    return (
        <Card loading={startTakeoverFormFillingResponseData.isLoading} title="Takeover Form">
            <Form form={form} layout='vertical'>
                <Space direction='vertical' style={{ width: "100%" }}>
                    <Card title={"Start Details"} style={{ marginBottom: 15 }}>
                        <Row gutter={48}>
                            <Col span={6}>
                                <Form.Item
                                    label={<strong>Roaming Attendant</strong>}
                                >
                                    <Select
                                        placeholder="Select Roaming Attendant"
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        onSelect={raSelected}
                                        onClear={raCleared}
                                    >
                                        {raList?.map((option) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.displayName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="dbTableSiteId"
                                    label={<strong>Sites</strong>}
                                >
                                    <Select
                                        placeholder="Select Site"
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        disabled={currentQuestions}
                                    >
                                        {siteList?.map((option) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.site_id}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    {currentQuestions?.length > 0 && <>
                        <Card title="Site Details">
                            <Row gutter={48}>
                                <Col span={6}>
                                    <Form.Item label="Takeover Date" name="takeover_date">
                                        <DatePicker
                                            allowClear
                                            format={"DD-MM-YYYY"}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Site Id" name="site_id">
                                        <Input placeholder='Site Id' disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Previous RA Agency" name="previous_ra_agency">
                                        <Input placeholder='Previous RA Agency' />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="New  RA Agency" name="new_ra_agency">
                                        <Input placeholder='New  RA Agency' />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Site Type" name="site_type">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={9}>
                                    <Form.Item label="Address" name="address">
                                        <Input.TextArea rows={3} placeholder='address' disabled />
                                    </Form.Item>
                                </Col>

                                <Col span={9}>
                                    <Form.Item label="Remarks" name="remarks">
                                        <Input.TextArea rows={3} placeholder='Remarks' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <List
                            grid={{
                                gutter: 16,
                                column: 1
                            }}
                            dataSource={currentQuestions}
                            renderItem={(item, index) => (
                                <List.Item key={index}>
                                    <Card title={(index + 1) + ". " + item.questionHeading}>
                                        <Row>
                                            {item.subQuestion?.map((subQuestion, index) =>
                                                <Col span={24} style={{ marginTop: 10 }}>
                                                    <Card title={(index + 1) + ". " + subQuestion.heading}>
                                                        <Form.Item label="" name={item.questionId + "sub" + subQuestion.id} rules={[{
                                                            required: true
                                                        }]}>
                                                            <Radio.Group key={index} onChange={handleAnswerChange}>
                                                                <Space>
                                                                    {subQuestion.options.map(option =>
                                                                        <Radio value={option.id}>{option.tittle}</Radio>
                                                                    )}
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            {item.quantity && <Col span={6}>
                                                <Form.Item label="Quantity" name={item.questionId + "quantity"} style={{ marginTop: 10 }}>
                                                    <InputNumber placeholder='Quantity' />
                                                </Form.Item>
                                            </Col>}
                                            {item.remarks && <Col span={24}>
                                                <Form.Item label="Remarks" name={item.questionId + "remarks"} style={{ marginTop: 10 }}>
                                                    <Input.TextArea rows={3} placeholder='Remark' />
                                                </Form.Item>
                                            </Col>}
                                        </Row>
                                    </Card>
                                </List.Item>

                            )}
                        />
                        {extraAssets &&
                            <List
                                grid={{
                                    gutter: 16,
                                    column: 1
                                }}
                                dataSource={extraAssets}
                                renderItem={(item, index) => (
                                    <List.Item key={index}>
                                        <Card title={"Extra Assets - " + (index + 1)}>
                                            <Row>
                                                <Col span={24} style={{ marginTop: 10 }}>
                                                    <Form.Item label="Asset Name" name={"extra_asset_name" + index} rules={[{
                                                        required: false
                                                    }]}>
                                                        <Input maxLength={50} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24} style={{ marginTop: 10 }}>
                                                    <Card title={"Is Available?"}>
                                                        <Form.Item label="" name={"extra_asset" + index + "" + 1} rules={[{
                                                            required: false
                                                        }]}>
                                                            <Radio.Group key={index} onChange={handleAnswerChange}>
                                                                <Space>
                                                                    {[{ id: 1, tittle: "Yes" }, { id: 2, tittle: "No" }].map(option =>
                                                                        <Radio value={option.id}>{option.tittle}</Radio>
                                                                    )}
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Card>
                                                </Col>
                                                <Col span={24} style={{ marginTop: 10 }}>
                                                    <Card title={"Is Working?"}>
                                                        <Form.Item label="" name={"extra_asset" + index + "" + 2} rules={[{
                                                            required: false
                                                        }]}>
                                                            <Radio.Group key={index} onChange={handleAnswerChange}>
                                                                <Space>
                                                                    {[{ id: 1, tittle: "Yes" }, { id: 2, tittle: "No" }].map(option =>
                                                                        <Radio value={option.id}>{option.tittle}</Radio>
                                                                    )}
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col span={24}>
                                                    <Form.Item label="Remarks" name={"extra_asset_quantity" + index} style={{ marginTop: 10 }}>
                                                        <InputNumber placeholder='Quantity' />
                                                    </Form.Item>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item label="Remarks" name={"extra_asset_remarks" + index} style={{ marginTop: 10 }}>
                                                        <Input.TextArea rows={3} placeholder='Remark' />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Button type='primary' disabled={item?.id - 1 == index} onClick={() => addExtraAsset(index)}>Save Extra Asset</Button>
                                        </Card>

                                    </List.Item>

                                )}
                            />}
                        <Row>
                            <Col span={24}>
                                <Card title={"Previous Service Provider Details"}>
                                    <Row gutter={48}>
                                        <Col span={12}>
                                            <Form.Item label="Service Provider Name" name="previous_service_provider_name">
                                                <Input placeholder='Service Provider Name' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Name" name="previous_employee_name">
                                                <Input placeholder='Emp. Name' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Designation" name="previous_employee_designation">
                                                <Input placeholder='Emp. Designation' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Id" name="previous_employee_id">
                                                <Input placeholder='Emp. Id' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Signature" name={"previous_employee_signature"} style={{ marginLeft: 25 }}>
                                                <Upload
                                                    {...uploadProps}
                                                    customRequest={dummyRequest}
                                                    listType="picture-card"
                                                    // fileList={fileList}
                                                    // onChange={handleChange}
                                                    maxCount={1}
                                                    multiple={false}
                                                >
                                                    {
                                                        <UploadOutlined />
                                                    }
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Selfie" name={"previous_employee_selfie"} style={{ marginLeft: 25 }}>
                                                <Upload
                                                    {...uploadProps}
                                                    customRequest={dummyRequest}
                                                    listType="picture-card"
                                                    // fileList={fileList}
                                                    // onChange={handleChange}
                                                    maxCount={1}
                                                    multiple={false}
                                                >
                                                    {
                                                        <UploadOutlined />
                                                    }
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Card title={"New Service Provider Details"}>
                                    <Row gutter={48}>
                                        <Col span={12}>
                                            <Form.Item label="Service Provider Name" name="new_service_provider_name">
                                                <Input placeholder='Service Provider Name' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Name" name="new_employee_name">
                                                <Input placeholder='Emp. Name' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Designation" name="new_employee_designation">
                                                <Input placeholder='Emp. Designation' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Id" name="new_employee_id">
                                                <Input placeholder='Emp. Id' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Signature" name={"new_employee_signature"} style={{ marginLeft: 25 }}>
                                                <Upload
                                                    {...uploadProps}
                                                    customRequest={dummyRequest}
                                                    listType="picture-card"
                                                    // fileList={fileList}
                                                    // onChange={handleChange}
                                                    maxCount={1}
                                                    multiple={false}
                                                >
                                                    {
                                                        <UploadOutlined />
                                                    }
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Selfie" name={"new_employee_selfie"} style={{ marginLeft: 25 }}>
                                                <Upload
                                                    {...uploadProps}
                                                    customRequest={dummyRequest}
                                                    listType="picture-card"
                                                    // fileList={fileList}
                                                    // onChange={handleChange}
                                                    maxCount={1}
                                                    multiple={false}
                                                >
                                                    {
                                                        <UploadOutlined />
                                                    }
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </>}
                </Space>
            </Form>

            <Space style={{ float: "right" }}>
                <Button
                    type="default"
                    onClick={() => navigate(-1)}
                    disabled={addTakeoverFormResponseData.isLoading}
                >
                    Cancel
                </Button>
                {currentQuestions ? <Button
                    type="primary"
                    onClick={addSiteTakeoverForm}
                    loading={addTakeoverFormResponseData.isLoading}
                >
                    Save
                </Button>
                    : <Button
                        type="primary"
                        onClick={startTakeoverFormFilling}
                        loading={startTakeoverFormFillingResponseData.isLoading}
                    >
                        Start
                    </Button>}
            </Space>
        </Card >
    );
}

export default MISAddTakeoverForm;