import { Button, Card, Col,DatePicker, Form, Modal, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import TableComponent from "../../common/table/TableComponent";
import { getClientConfig, isEmpty,getDataFromLocalStorage, setDataToLocalStorage, disableFutureDates } from "../../../generalFunctions";
import dayjs from "dayjs";

function ShutterActivityTable() {
  const getActivity = (text) => {
    if (text === true) {
      return "Open";
    } else if (text === false) {
      return "Close";
    }
  };

  const { ra, site } = getClientConfig();
  const columns = [
    {
      title: "Site Id",
      dataIndex: "siteId",
    },
    {
      title: "Filled By",
      dataIndex: "filledByName",
    },
    {
      title: "Activity",
      dataIndex: "activity",
      render: (text) => getActivity(text),
    },
    {
      title: "Submitted At",
      dataIndex: "timestamp",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button size="small"
          disabled={isEmpty(record.selfie)}
          onClick={() => showSelfie(record.selfie)}>
          Show Selfie
        </Button>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/admin/shutter-activity?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );

  const [tableData, setTableData] = useState();
  const [form] = Form.useForm();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSelfieModal, setShowSelfieModal] = useState(false);
  const [selectedSelfie, setSelectedSelfie] = useState();

  useEffect(() => {
    let filters = getDataFromLocalStorage("ShutterActivity");
    let url = `/admin/shutter-activity?pageNumber=${1}`;
    if (filters) {
      if (filters.date) {
        filters.date = dayjs(filters.date);
      }
      form.setFieldsValue(filters);
      url = filters.raId ? url + `&filledBy=${filters.raId}` : url;
      url = filters.siteId ? url + `&dbTableSiteId=${filters.siteId}` : url;
      url = filters.date
        ? url + `&date=${dayjs(filters.date).format("YYYY-MM-DD")}`
        : url;
    }
    getRequest(null, API_METHODS.GET, url);
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    const pagination =
      responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [responseData]);

  const onPaginationChange = (page) => {
    let url = `/admin/shutter-activity?pageNumber=${page.current}`;
    const values = form.getFieldsValue();
    url = values.raId ? url + `&filledBy=${values.raId}` : url;
    url = values.siteId ? url + `&dbTableSiteId=${values.siteId}` : url;
    url = values.date
      ? url + `&date=${dayjs(values.date).format("YYYY-MM-DD")}`
      : url;
    getRequest(null, API_METHODS.GET, url);
    setCurrentPage(page.current);
  };

  const filterResults = () => {
    const values = form.getFieldsValue();
    let url = `/admin/shutter-activity?pageNumber=${1}`;
    url = values.raId ? url + `&filledBy=${values.raId}` : url;
    url = values.siteId ? url + `&dbTableSiteId=${values.siteId}` : url;
    url = values.date
      ? url + `&date=${dayjs(values.date).format("YYYY-MM-DD")}`
      : url;
    setCurrentPage(1);
    setDataToLocalStorage("ShutterActivity", values);
    getRequest(null, API_METHODS.GET, url);
  };

  const showSelfie = (selfie) => {
    const isVisible = showSelfieModal ? false : true;
    setSelectedSelfie(selfie);
    setShowSelfieModal(isVisible);
  }

  return (
    <>
      <Card title="Shutter Activity">
        <Form form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="raId"
                label={<strong>Roaming Attendants</strong>}
              >
                <Select
                  placeholder="Select Roaming  Attendant"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ra.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.displayName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="siteId" label={<strong>Sites</strong>}>
                <Select
                  placeholder="Select Site"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {site.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.siteId}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="date" label={<strong>Date</strong>}>
                <DatePicker
                  allowClear
                  format={"DD-MM-YYYY"}
                  disabledDate={disableFutureDates}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Space>
                <Button type="primary" onClick={filterResults}>
                  Search
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    form.resetFields();
                    getRequest();
                    setCurrentPage(1);
                    localStorage.removeItem("ShutterActivity");
                  }}
                >
                  Reset
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      </Card>

      {showSelfieModal && (
        <Modal
          title="Selfie"
          open={showSelfieModal}
          footer={null}
          width={250}
          onCancel={showSelfie}
          destroyOnClose={true}
        >
          <img
            src={selectedSelfie}
            style={{ height: 250, width: 200 }}
            alt="Selfie"
          />
        </Modal>
      )}
    </>
  );
}

export default ShutterActivityTable;
