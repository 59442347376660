import { Button, Card, Col, DatePicker, Form, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import TableComponent from "../common/table/TableComponent";
import { disableFutureDates, replaceEmpty } from "../../generalFunctions";
import dayjs from "dayjs";

function SupervisorVisitFormReport() {
  let reportHeaders = [
    [
      "Supervisor Name",
      "RA Name",
      "Address",
      "Site Id",
      "Visit Date",
      "Visit Time",
      "Cleanliness Related",
      "Functionality Related",
      "Material Related",
      "Reward point",
    ],
  ];

  const columns = [
    {
      title: "Supervisor",
      dataIndex: "supervisorName",
    },
    {
      title: "RA",
      dataIndex: "raName",
    },
    {
      title: "Site Id",
      dataIndex: "siteId",
    },
    {
      title: "Date of Visit",
      dataIndex: "dateOfVisit",
    },
  ];

  const [responseData, getRequest] = useApi(
    "/admin/reports/supervisor-visit-form",
    null,
    API_METHODS.POST
  );

  const [tableData, setTableData] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    data = replaceEmpty(data);
    setTableData(data);
  }, [responseData]);

  const getForms = () => {
    form
      .validateFields()
      .then((values) => {
        let requestBody = {};
        requestBody.startDate = dayjs(values.startDate);
        requestBody.endDate = dayjs(values.endDate);
        getRequest(requestBody);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Card title="Supervisor Visit Form Report">
      <Form form={form}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="startDate"
              label={<strong>From Date</strong>}
              rules={[{ required: true }]}
            >
              <DatePicker
                allowClear
                format={"DD-MM-YYYY"}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="endDate"
              label={<strong>To Date</strong>}
              rules={[{ required: true }]}
            >
              <DatePicker
                allowClear
                format={"DD-MM-YYYY"}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Space>
              <Button type="primary" onClick={getForms}>
                Search
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form.resetFields();
                }}
              >
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        clientSideRender={true}
        showDownloadOption={true}
        reportHeaders={reportHeaders}
        reportData={tableData}
        exportFileName={
          "SupervisorVisitForm-" +
          form.getFieldValue("startDate")?.format("DD-MM-YYYY") +
          "-To-" +
          form.getFieldValue("endDate")?.format("DD-MM-YYYY")
        }
      />
    </Card>
  );
}

export default SupervisorVisitFormReport;
