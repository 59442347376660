import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Input, List, Radio, Row, Space, Upload, message } from 'antd';
import { API_METHODS, BLANK_VALUE } from '../../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../hooks/useApi';
import { UploadOutlined } from "@ant-design/icons";
import { isEmpty } from '../../../generalFunctions';
import { useImageDownloadZip } from '../../../hooks/useImageDownloadZip';

const UpdateQRTForm = (props) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { handleZip } = useImageDownloadZip();
    const [siteVisitDetails, setSiteVisitDetails] = useState();
    const [dummyState, setDummyState] = useState();
    const { qrtFormId } = useParams();
    const [responseData, getRequest] = useApi(
        `/mis/qrt-form/${qrtFormId}`,
        null,
        API_METHODS.GET
    );
    const [updateResponseData, updateRequest] = useApi(
        `/mis/qrt-form/update/${qrtFormId}`,
        null,
        API_METHODS.PUT,
        true
    );

    const updateQRTForm = () => {
        form.validateFields().then((values, fileList) => {
            let newAnswers = [];
            let newImages = [];
            let questionIds = Object.keys(values);
            for (let i = 0; i < questionIds.length; i++) {
                if (!questionIds[i].toString().includes("images")) {
                    const questionId = questionIds[i];
                    let answerData;
                    if (values[questionId]) {
                        if (questionId !== "7") {
                            answerData = {
                                "images": siteVisitDetails.currentQuestionAnswers.find(a => a.questionId == questionId)?.images,
                                "answerId": values[questionId],
                                "questionId": questionId
                            }
                        } else {
                            answerData = {
                                "images": siteVisitDetails.currentQuestionAnswers.find(a => a.questionId == questionId)?.images,
                                "remarks": values[questionId],
                                "questionId": questionId
                            }
                        }

                        newAnswers.push(answerData);
                    }
                    if (values["images" + questionId]) {
                        const newImagesArray = (values["images" + questionId].fileList.map(f => { return { questionId: questionId, originFileObj: f.originFileObj, name: f.name, } }));
                        newImages = [...newImages, ...newImagesArray];
                    }
                }
            };

            const formData = new FormData();
            for (let i = 0; i < newImages.length; i++) {
                formData.append(
                    newImages[i].questionId,
                    newImages[i].originFileObj,
                    newImages[i].name,
                );
            }
            formData.append("answers", JSON.stringify(newAnswers));
            updateRequest(formData);
        }).catch(e => console.log(e));
    }

    const uploadProps = {
        beforeUpload: (file) => {
            const isImage = file.type === "image/png" || file.type === "image/jpeg";
            if (!isImage) {
                message.error(`${file.name} error. Only PNG and JPEG files supported`);
            }
            return isImage || Upload.LIST_IGNORE;
        },
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleAnswerChange = (e) => {
        setDummyState(e);
    }

    const deleteImage = (record, image) => {
        let newSiteVisitDetails = { ...siteVisitDetails };
        let modifiedAnswer = newSiteVisitDetails.currentQuestionAnswers.find(a => a.questionId == record.questionId);
        const remainingImages = modifiedAnswer.images.filter(i => i !== image);
        // if (remainingImages.length > 0) {
        modifiedAnswer.images = remainingImages;
        setSiteVisitDetails(newSiteVisitDetails);
        // } else {
        //     message.warning("Atleast one image is required.");
        // }
    }

    const downloadImages = () => {
        const zipFileName = "QRT Form - " + siteVisitDetails?.site_id + " by " + (siteVisitDetails?.filled_by_name.toUpperCase()) + " @ " + siteVisitDetails?.submit_timestamp;
        let images = [];
        images.push(siteVisitDetails.start_selfie);
        siteVisitDetails.currentQuestionAnswers.forEach(item => {
            if (item.images?.length > 0) {
                const imagesData = item.images.map(item => item);
                images = [...images, ...imagesData];
            }
        })
        handleZip(images, zipFileName);
    }

    useEffect(() => {
        if (!responseData.isLoading) {
            let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
            if (data) {
                let initialValues = {};
                let currentQuestionAnswers = [];
                data[0].questions.forEach(q => {
                    let questionWithAnswer = { ...q };
                    let answer = data[0].answers.find(a => a.questionId == questionWithAnswer.questionId);
                    if (questionWithAnswer.questionId !== 7) {
                        questionWithAnswer.answerId = answer ? answer.answerId : undefined;
                    } else {
                        questionWithAnswer.answerId = answer ? answer.remarks : undefined;
                    }
                    questionWithAnswer.images = answer ? answer.images : [];
                    currentQuestionAnswers.push(questionWithAnswer);
                });
                data[0].currentQuestionAnswers = currentQuestionAnswers;
                setSiteVisitDetails(data[0]);

                currentQuestionAnswers.forEach((question, index) => {
                    let initialAnswer = {};
                    if (question.questionId !== 7) {
                        initialAnswer = { [question.questionId]: parseInt(question.answerId) }

                    } else {
                        initialAnswer = { [question.questionId]: question.answerId }
                    }
                    initialValues = { ...initialValues, ...initialAnswer };
                });
                form.setFieldsValue(initialValues);
            }
        }
    }, [responseData]);

    useEffect(() => {
        if (updateResponseData.data && !updateResponseData.isLoading && isEmpty(updateResponseData.error)) {
            message.info("QRT form updated successfully.");
            navigate(-1);
        } else if (updateResponseData.error && !updateResponseData.isLoading) {
            message.info(updateResponseData.error);
        }
    }, [updateResponseData]);


    useEffect(() => {
        getRequest();
    }, []);

    return (
        <Card loading={responseData.isLoading} title={"QRT Form - " + siteVisitDetails?.site_id + " by " + (siteVisitDetails?.filled_by_name.toUpperCase()) + " @ " + siteVisitDetails?.submit_timestamp}
            extra={<Button onClick={downloadImages}>Download Images</Button>}>
            {siteVisitDetails?.currentQuestionAnswers?.length > 0 &&
                <Form form={form} layout='vertical'>
                    <Card title={"Start Selfie"} style={{ marginBottom: 15 }}>
                        <Image
                            width={100}
                            height={100}
                            src={siteVisitDetails.start_selfie}
                            style={{ marginLeft: 10 }}
                            placeholder={
                                <Image preview={false} src={siteVisitDetails.start_selfie} width={100} style={{ marginLeft: 10 }} />
                            }
                        />
                    </Card>
                    <List
                        grid={{
                            gutter: 16,
                            column: 1
                        }}
                        dataSource={siteVisitDetails.currentQuestionAnswers}
                        renderItem={(item, index) => (
                            <List.Item>
                                <Card title={(index + 1) + ". " + item.questionHeading}>
                                    {(item.questionId !== 7) ? <Form.Item label="" name={item.questionId}>
                                        <Radio.Group key={item.questionId} onChange={handleAnswerChange}>
                                            <Space>
                                                {item.options.map(option =>
                                                    <Radio value={option.id}>{option.tittle}</Radio>
                                                )}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item> :
                                        <Form.Item label="" name={item.questionId} style={{ marginTop: 10 }}>
                                            <Input.TextArea rows={3} placeholder='Remarks' />
                                        </Form.Item>}
                                    <Row>
                                        <Space>
                                            {item.images?.length > 0 && (item.options.find(option => option.id === form.getFieldValue(item.questionId))?.isImageSelectable || item.type === 2) &&
                                                <Space>
                                                    {item.images.map(image => <Space direction='vertical' style={{ width: "100%" }}>
                                                        <Image
                                                            width={100}
                                                            height={100}
                                                            src={image}
                                                            style={{ marginLeft: 10 }}
                                                            placeholder={
                                                                <Image preview={false} src={image} width={100} style={{ marginLeft: 10 }} />
                                                            }
                                                        /><Button size='small' style={{ marginLeft: 10 }} onClick={() => deleteImage(item, image)}>delete</Button></Space>)}
                                                </Space>
                                            }
                                            {(item.options.find(option => option.id === form.getFieldValue(item.questionId))?.isImageSelectable || item.type === 2) &&
                                                <Form.Item name={"images" + item.questionId} label="" style={{ marginLeft: 25 }}
                                                    rules={[{
                                                        required: (item.images?.length === 0 && item.options.find(option => option.id === form.getFieldValue(item.questionId))?.isImageSelectable),
                                                        message: "Please upload image"
                                                    }]}>
                                                    <Upload
                                                        {...uploadProps}
                                                        customRequest={dummyRequest}
                                                        listType="picture-card"
                                                        // fileList={fileList}
                                                        // onChange={handleChange}
                                                        multiple={true}
                                                    >
                                                        {
                                                            <UploadOutlined />
                                                        }
                                                    </Upload>
                                                </Form.Item>
                                            }
                                        </Space>
                                    </Row>
                                </Card>
                            </List.Item>

                        )}
                    />
                </Form>
            }
            <Space style={{ float: "right" }}>
                <Button
                    type="default"
                    onClick={() => navigate(-1)}
                    disabled={updateResponseData.isLoading}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={updateQRTForm}
                    loading={updateResponseData.isLoading}
                >
                    Update
                </Button>
            </Space>
        </Card >
    );
}

export default UpdateQRTForm;