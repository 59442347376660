import { Button, Card, Col, Form, Space, Tabs, Row, Select, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";
import { EyeOutlined } from "@ant-design/icons";
import { getClientConfig as config, disableFutureDates } from "../../generalFunctions";
import ViewMaterialRequirement from "./ViewMaterialRequirement";
import dayjs from "dayjs";

function MaterialRequirementTable() {
  const [activeTab, setActiveTab] = useState("pending");
  const { ra, supervisor } = config();
  const [visible, setVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const columns = [
    {
      title: "RA",
      dataIndex: "ra",
    },
    {
      title: "Supervisor",
      dataIndex: "supervisor",
    },
    {
      title: "Requirement Raised On",
      dataIndex: "requirementRaisedAt",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Space>
            <Button size="small" onClick={() => viewRecord(record)}>
              <EyeOutlined />
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/admin/material-requirement/get?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [fulfilledRequests, getFulfilledRequests] = useApi(
    `/admin/material-requirement/fulfilled?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );

  const [tableData, setTableData] = useState();
  const [form] = Form.useForm();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    const pagination =
      responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [responseData]);

  useEffect(() => {
    const data =
      fulfilledRequests.data !== BLANK_VALUE ? fulfilledRequests.data : [];
    const pagination =
      fulfilledRequests.pagination !== BLANK_VALUE
        ? fulfilledRequests.pagination
        : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [fulfilledRequests]);

  const viewRecord = (record) => {
    setVisible(true);
    setSelectedRecord(record);
  };

  const handleCloseView = () => {
    setVisible(false);
    setSelectedRecord();
    form.resetFields();
    setActiveTab("pending");
    setCurrentPage(1);
    getRequest();
  };

  const onPaginationChange = (page) => {
    setCurrentPage(page.current);
    const values = form.getFieldsValue();
    let url = `/admin/material-requirement/get?pageNumber=${page.current}`;
    if (activeTab === "fulfilled") {
      url = `/admin/material-requirement/fulfilled?pageNumber=${page.current}`;
      url = values.raId ? url + `&raId=${values.raId}` : url;
      url = values.supervisorId
        ? url + `&supervisorId=${values.supervisorId}`
        : url;
      url = values.date
        ? url + `&date=${dayjs(values.date).format("YYYY-MM-DD")}`
        : url;
      getFulfilledRequests(null, API_METHODS.GET, url);
    } else {
      url = values.raId ? url + `&raId=${values.raId}` : url;
      url = values.supervisorId
        ? url + `&supervisorId=${values.supervisorId}`
        : url;
      url = values.date
        ? url + `&date=${dayjs(values.date).format("YYYY-MM-DD")}`
        : url;        
      getRequest(null, API_METHODS.GET, url);
    }
  };

  const onTabChange = (key) => {
    form.resetFields();
    setActiveTab(key);
    setCurrentPage(1);
    if (key === "fulfilled") {
      getFulfilledRequests();
    } else {
      getRequest();
    }
  };

  const filterResults = () => {
    const values = form.getFieldsValue();
    setCurrentPage(1);
    let url = `/admin/material-requirement/get?pageNumber=${1}`;
    if (activeTab === "fulfilled") {
      url = `/admin/material-requirement/fulfilled?pageNumber=${1}`;
      url = values.raId ? url + `&raId=${values.raId}` : url;
      url = values.supervisorId
        ? url + `&supervisorId=${values.supervisorId}`
        : url;
      url = values.date
        ? url + `&date=${dayjs(values.date).format("YYYY-MM-DD")}`
        : url;
      getFulfilledRequests(null, API_METHODS.GET, url);
    } else {
      url = values.raId ? url + `&raId=${values.raId}` : url;
      url = values.supervisorId
        ? url + `&supervisorId=${values.supervisorId}`
        : url;
      url = values.date
        ? url + `&date=${dayjs(values.date).format("YYYY-MM-DD")}`
        : url;
      getRequest(null, API_METHODS.GET, url);
    }
  };

  const resetResult = () => {
    setCurrentPage(1);
    form.resetFields();
    if (activeTab === "fulfilled") {
      getFulfilledRequests();
    } else {
      getRequest();
    }
  };

  const items = [
    {
      key: "pending",
      label: "Pending Requests",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "fulfilled",
      label: "Request approved and rejected",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={fulfilledRequests.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  return (
    <Card title="Material Requirement">
      <Form form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="raId" label={<strong>Roming Attendant</strong>}>
              <Select
                placeholder="Select RA"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {ra.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="supervisorId" label={<strong>Supervisor</strong>}>
              <Select
                placeholder="Select Supervisor"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {supervisor.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="date" label={<strong>Date</strong>}>
              <DatePicker
                allowClear
                format={"DD-MM-YYYY"}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Space>
              <Button type="primary" onClick={filterResults}>
                Search
              </Button>
              <Button type="primary" onClick={resetResult}>
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Tabs
        defaultActiveKey="pending"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
      />

      {visible && (
        <ViewMaterialRequirement
          visible={visible}
          close={handleCloseView}
          selectedRecord={selectedRecord}
        />
      )}
    </Card>
  );
}

export default MaterialRequirementTable;
