import React, { useEffect } from "react";
import { Modal, Form, Input, Col, Row, message, InputNumber } from "antd";
import { API_METHODS } from "../../../constants";
import useApi from "../../../hooks/useApi";

function AddCustomer(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord, edit } = props;

  const [response, request] = useApi(
    "/config/customer",
    null,
    API_METHODS.POST
  );
  const [editResponse, editRequest] = useApi(
    `/config/customer/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Customer added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Customer updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        editRequest(values);
      } else {
        request(values, API_METHODS.POST);
      }
    }).catch(e => { });
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title={selectedRecord ? "Update Customer" : "Add Customer"}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="customerName"
              label="Customer Name"
              rules={[
                { required: true, message: "Customer Name is required." },
                {
                  max: 100,
                  message: "Customer Name should be maximum of 100 characters.",
                },
              ]}
            >
              <Input placeholder="Customer Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="visitFormTimer"
              label="Visit Form Timer"
              rules={[
                { required: true, message: "Visit Form Timer is required." },
              ]}
            >
              <InputNumber placeholder="Visit Form Timer" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddCustomer;
