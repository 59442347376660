import React, { useEffect } from "react";
import { setDataToLocalStorage } from "../generalFunctions";
import { API_METHODS } from "../constants";
import useApi from "../hooks/useApi";
import { Card } from "antd";

function AdminDashboard() {
  const [clientConfig, getClientConfig] = useApi(
    "/config",
    null,
    API_METHODS.GET
  );

  useEffect(() => {
    getClientConfig();
  }, []);

  useEffect(() => {
    if (clientConfig.data) {
      setDataToLocalStorage("clientConfig", clientConfig.data);
      // if (location.pathname === "/portal") {
      //   navigate("/portal/dashboard");
      // }
    }
  }, [clientConfig]);

  return <Card style={{ height: 250, textAlign: "center", alignContent: "center" }}>
    <h2>Coming Soon</h2>
  </Card>
}

export default AdminDashboard;
