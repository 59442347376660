import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../hooks/useApi';
import { API_METHODS, BLANK_VALUE } from '../../../constants';
import { Button, Card, Col, Form, Image,  List, Radio, Row, Space } from 'antd';
import { useImageDownloadZip } from '../../../hooks/useImageDownloadZip';

const MISSupervisorVisitFormDetails = (props) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { handleZip } = useImageDownloadZip();
    const [siteVisitDetails, setSiteVisitDetails] = useState();
    const { formId } = useParams();
    const [responseData, getRequest] = useApi(
        `/mis/supervisor-visit-form/${formId}`,
        null,
        API_METHODS.GET
    );

    const downloadImages = () => {
        const zipFileName = "Supervisor Visit Form - " + siteVisitDetails?.site_id + " by " + (siteVisitDetails?.filled_by_name.toUpperCase()) + " @ " + siteVisitDetails?.submit_timestamp;
        let images = [];
        images.push(siteVisitDetails.start_selfie);
        siteVisitDetails.currentQuestionAnswers.forEach(item => {
            if (item.images?.length > 0) {
                const imagesData = item.images.map(item => item);
                images = [...images, ...imagesData];
            }
            if (item.subquestions) {
                item.subquestions.forEach(subQuestion => {
                    if (subQuestion.images?.length > 0) {
                        const imagesData = subQuestion.images.map((item) => item);
                        images = [...images, ...imagesData];
                    }
                })
            }
        })
        images.push(...siteVisitDetails.site_images);
        handleZip(images, zipFileName);
    }

    useEffect(() => {
        getRequest();
    }, []);

    useEffect(() => {
        if (!responseData.isLoading) {
            let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
            if (data) {
                let initialValues = {};
                let currentQuestionAnswers = [];
                data[0].questions.forEach(q => {
                    let questionWithAnswer = { ...q };
                    let answer = data[0].answers.find(a => a.questionId == questionWithAnswer.questionId && parseInt(a.section) == questionWithAnswer.section);
                    questionWithAnswer.answerId = answer ? answer.answerId : undefined;
                    questionWithAnswer.images = answer ? answer.images : [];
                    currentQuestionAnswers.push(questionWithAnswer);
                });
                data[0].currentQuestionAnswers = currentQuestionAnswers;
                setSiteVisitDetails(data[0]);

                currentQuestionAnswers.forEach((question, index) => {
                    let initialAnswer = {};
                    initialAnswer = { [question.questionId.toString() + question.section.toString()]: parseInt(question.answerId) }
                    initialValues = { ...initialValues, ...initialAnswer };
                });
                console.log(currentQuestionAnswers, initialValues);
                form.setFieldsValue(initialValues);
            }
        }
    }, [responseData]);

    return (<Card loading={responseData.isLoading} title={"Supervisor Visit Form - " + siteVisitDetails?.site_id + " by " + (siteVisitDetails?.filled_by_name.toUpperCase()) + " @ " + siteVisitDetails?.submit_timestamp}
        extra={<Button onClick={downloadImages}>Download Images</Button>}>
        {siteVisitDetails?.currentQuestionAnswers?.length > 0 &&
            <Form form={form} layout='vertical'>
                <Card title={"Start Selfie"} style={{ marginBottom: 15 }}>
                    <Image
                        width={100}
                        height={100}
                        src={siteVisitDetails.start_selfie}
                        style={{ marginLeft: 10 }}
                        placeholder={
                            <Image preview={false} src={siteVisitDetails.start_selfie} width={100} style={{ marginLeft: 10 }} />
                        }
                    />
                </Card>
                <List
                    grid={{
                        gutter: 16,
                        column: 1
                    }}
                    dataSource={siteVisitDetails.currentQuestionAnswers}
                    renderItem={(item, index) => (
                        <List.Item>
                            <Card title={(index + 1) + ". " + item.questionHeading}
                                extra={<h4 style={{ color: "#fff" }}>{item.heading}</h4>}>
                                <Form.Item label="" name={item.questionId.toString() + item.section.toString()}>
                                    <Radio.Group key={item.questionId.toString() + item.section.toString()}>
                                        <Space>
                                            {item.options.map(option =>
                                                <Radio value={option.id}>{option.tittle}</Radio>
                                            )}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                                <Row>
                                    <Space>
                                        {item.images?.length > 0 && (item.options.find(option => option.id === form.getFieldValue(item.questionId.toString() + item.section.toString()))?.isImageSelectable || item.type === 2) &&
                                            <Space>
                                                {item.images.map(image => <Space direction='vertical' style={{ width: "100%" }}>
                                                    <Image
                                                        width={100}
                                                        height={100}
                                                        src={image}
                                                        style={{ marginLeft: 10 }}
                                                        placeholder={
                                                            <Image preview={false} src={image} width={100} style={{ marginLeft: 10 }} />
                                                        }
                                                    /></Space>)}
                                            </Space>
                                        }
                                    </Space>
                                </Row>
                            </Card>
                        </List.Item>

                    )}
                />
                <Card title={"Site Images"}>
                    <Col span={22}>
                        <Space>
                            {siteVisitDetails.site_images?.length > 0 && siteVisitDetails.site_images.map(image =>
                                <Space direction='vertical' style={{ width: "100%" }}>
                                    <Image
                                        width={100}
                                        height={100}
                                        src={image}
                                        style={{ marginLeft: 10 }}
                                        placeholder={
                                            <Image preview={false} src={image} width={100} style={{ marginLeft: 10 }} />
                                        }
                                    />
                                </Space>)}
                        </Space>
                    </Col>
                </Card>
            </Form>
        }
        <Space style={{ float: "right" }}>
            <Button
                type="default"
                onClick={() => navigate(-1)}
            >
                Close
            </Button>
        </Space>
    </Card >);
}

export default MISSupervisorVisitFormDetails;