import {
  Button,
  Card,
  Form,
  message,
  Modal,
  Popconfirm,
  Space,
  Tabs,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import AddUser from "./AddUser";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  disableFutureDates,
  isEmpty,
  setDataToLocalStorage,
} from "../../../generalFunctions";

// import noImage from "../../../assests/images/no-image.png";
function UserTable() {
  const [activeTab, setActiveTab] = useState("admin");
  const [showDelete, setShowDelete] = useState(false);
  const [confirmDeletePCLoading, setConfirmDeletePCLoading] = useState(false);
  const [confirmReactivatePCLoading, setConfirmReactivatePCLoading] =
    useState(false);
  const [dropdownUsers, setDropdownUsers] = useState([]);
  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
    },
    {
      title: "Display Name",
      dataIndex: "displayName",
    },
    {
      title: "Role",
      dataIndex: "role",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {activeTab !== "deactivated" && (
            <Space>
              <Button size="small" onClick={() => editRecord(record)}>
                <EditOutlined />
              </Button>
              <Button size="small" onClick={() => { setSelectedRecord(record); setShowDelete(true); }}>
                <DeleteOutlined style={{ color: "red" }} />
              </Button>
              <Button size="small" onClick={() => updatePasswordRecord(record)}>
                Update Password
              </Button>


              {activeTab === "roaming-attendant" && (
                <Button size="small" onClick={() => viewRewards(record)}>
                  View Rewards
                </Button>
              )}
            </Space>
          )}
          {activeTab === "deactivated" && (
            <Space>
              <Popconfirm
                title="Reactivate User"
                description={`Are you sure to reactivate ${record.username}?`}
                onConfirm={() => reactivateRecord(record)}
                trigger={"click"}
                okButtonProps={{
                  loading: confirmReactivatePCLoading,
                }}
                placement="rightTop"
              >
                <Button size="small">Reactivate</Button>
              </Popconfirm>
            </Space>
          )}
        </>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/config/user?pageNumber=${1}&usertype=admin`,
    null,
    API_METHODS.GET
  );
  const [usersForDropdown, getUsersForDropdown] = useApi(
    `/config/user/dropdown?usertype=admin`,
    null,
    API_METHODS.GET
  );

  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteResponse, deleteRequest] = useApi();
  const [visibleUpdatePassword, setVisibleUpdatePassword] = useState(false);
  const [updatePasswordResponse, updatePasswordRequest] = useApi();
  const [visibleRewards, setVisibleRewards] = useState(false);
  const [form] = Form.useForm();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientConfig, getClientConfig] = useApi(
    "/config",
    null,
    API_METHODS.GET
  );

  const [deactivatedUsersData, getDeactivatedUsers] = useApi(
    `/config/user/deactivated?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [reactivateResponse, reactivateRequest] = useApi();
  const [rewardResponse, rewardsRequest] = useApi(
    `/rewards/`,
    null,
    API_METHODS.GET
  );

  const [redeemResponse, redeemRequest] = useApi(
    `/rewards/redeem`,
    null,
    API_METHODS.GET
  );

  const [points, setPoints] = useState(0);
  const [redeemPoints, setRedeemPoints] = useState();

  const refreshClientConfig = () => {
    getClientConfig();
  };

  useEffect(() => {
    if (clientConfig.data) {
      setDataToLocalStorage("clientConfig", clientConfig.data);
    }
  }, [clientConfig]);

  useEffect(() => {
    getRequest();
    getUsersForDropdown();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    const pagination =
      responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [responseData]);

  useEffect(() => {
    if (
      !isEmpty(usersForDropdown) &&
      !isEmpty(usersForDropdown.data) &&
      !usersForDropdown.isLoading
    ) {
      const data =
        usersForDropdown.data !== BLANK_VALUE ? usersForDropdown.data : [];
      setDropdownUsers(data);
    }
  }, [usersForDropdown]);

  useEffect(() => {
    const data =
      deactivatedUsersData.data !== BLANK_VALUE
        ? deactivatedUsersData.data
        : [];
    const pagination =
      deactivatedUsersData.pagination !== BLANK_VALUE
        ? deactivatedUsersData.pagination
        : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [deactivatedUsersData]);

  useEffect(() => {
    if (deleteResponse.data && !deleteResponse.isLoading) {
      getRequest();
      setCurrentPage(1);
      getUsersForDropdown();
      refreshClientConfig();
      setActiveTab("admin");
      setConfirmDeletePCLoading(false);
      setSelectedRecord(null);
      setShowDelete(false);
      message.success("User Deleted successfully.");
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (reactivateResponse.data && !reactivateResponse.isLoading) {
      getRequest();
      setCurrentPage(1);
      getUsersForDropdown();
      refreshClientConfig();
      setActiveTab("admin");
      setConfirmReactivatePCLoading(false);
      message.success("User Reactivated successfully.");
    }
  }, [reactivateResponse]);

  const viewRewards = (record) => {
    setSelectedRecord(record);
    rewardsRequest(null, API_METHODS.GET, `/rewards/${record.id}`);
  };

  const closeRewardModal = () => {
    setVisibleRewards(false);
    setSelectedRecord(null);
    setPoints(0);
  };

  useEffect(() => {
    if (rewardResponse.data && rewardResponse.isLoading !== true) {
      if (rewardResponse.data.length > 0) {
        setPoints(rewardResponse.data[0].points);
      }
      setVisibleRewards(true);
    }
  }, [rewardResponse]);

  useEffect(() => {
    if (redeemResponse.data) {
      setVisibleRewards(false);
      setSelectedRecord(null);
      setPoints(0);
      message.success("Points Redeemed successfully.");
    }
  }, [redeemResponse]);

  useEffect(() => {
    if (updatePasswordResponse.data && !updatePasswordResponse.isLoading) {
      setVisibleUpdatePassword(false);
      setSelectedRecord(null);
      getRequest();
      setCurrentPage(1);
      getUsersForDropdown();
      refreshClientConfig();
      form.resetFields();
      setActiveTab("admin");
      message.success("User Password updated successfully.");
    }
  }, [updatePasswordResponse]);

  const handleAddUser = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
      getUsersForDropdown();
      setCurrentPage(1);
      setActiveTab("admin");
      refreshClientConfig();
    }
  };
  const handleRedeem = () => {
    let values = {
      raId: selectedRecord.id,
      points: redeemPoints ? redeemPoints : 0,
    };
    redeemRequest(values, API_METHODS.POST);
  };

  const handleEditUser = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest();
      getUsersForDropdown();
      setCurrentPage(1);
      setActiveTab("admin");
      refreshClientConfig();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  const deleteOk = (record) => {
    form
      .validateFields()
      .then((values) => {
        let body = {
          date: values.date,
        };
        setConfirmDeletePCLoading(true);
        deleteRequest(body, API_METHODS.DELETE, `/config/user/${record.id}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const reactivateRecord = (record) => {
    setConfirmReactivatePCLoading(true);
    reactivateRequest(
      null,
      API_METHODS.POST,
      `/config/user/reactivate/${record.id}`
    );
  };

  const onPaginationChange = (page) => {
    getRequest(
      null,
      API_METHODS.GET,
      `/config/user?pageNumber=${page.current}&usertype=${activeTab}`
    );
    setCurrentPage(page.current);
  };

  const updatePasswordRecord = (record) => {
    setVisibleUpdatePassword(true);
    setSelectedRecord(record);
  };

  const updatePassword = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        updatePasswordRequest(
          values,
          API_METHODS.PUT,
          `/config/user/password/${selectedRecord && selectedRecord.id}`
        );
      }
    });
  };

  const handleCancelUpdatePassword = () => {
    setVisibleUpdatePassword(false);
    setSelectedRecord(null);
    form.resetFields();
  };
  const filterResults = () => {
    const values = form.getFieldsValue();
    setCurrentPage(1);
    getRequest(
      null,
      API_METHODS.GET,
      `/config/user?pageNumber=${1}&userId=${values.userId}`
    );
    // let url = `/config/site?pageNumber=${1}`;
    // if (activeTab === "deactivated") {
    //   url = `/config/site/deactivated?pageNumber=${1}`;
    //   url = values.dbTableSiteId
    //     ? url + `&dbTableSiteId=${values.dbTableSiteId}`
    //     : url;
    //   getDeactivatedSites(null, API_METHODS.GET, url);
    // } else {
    //   url = values.dbTableSiteId
    //     ? url + `&dbTableSiteId=${values.dbTableSiteId}`
    //     : url;
    //   getRequest(null, API_METHODS.GET, url);
    // }
  };

  const resetResult = () => {
    setCurrentPage(1);
    form.resetFields();
    setActiveTab("admin");
    getRequest();
    getUsersForDropdown();
  };

  const items = [
    {
      key: "admin",
      label: "Admin",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "roaming-attendant",
      label: "RA",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "supervisor",
      label: "Supervisor",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "mis",
      label: "MIS",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    // {
    //   key: "regional-head",
    //   label: "Regional Head",
    //   children: (
    //     <TableComponent
    //       columns={columns}
    //       tableData={tableData}
    //       loading={responseData.isLoading}
    //       onPaginationChange={onPaginationChange}
    //       total={totalRecords}
    //       current={currentPage}
    //     />
    //   ),
    // },
    // {
    //   key: "national-head",
    //   label: "National Head",
    //   children: (
    //     <TableComponent
    //       columns={columns}
    //       tableData={tableData}
    //       loading={responseData.isLoading}
    //       onPaginationChange={onPaginationChange}
    //       total={totalRecords}
    //       current={currentPage}
    //     />
    //   ),
    // },
    // {
    //   key: "bank-employee",
    //   label: "Bank Employee",
    //   children: (
    //     <TableComponent
    //       columns={columns}
    //       tableData={tableData}
    //       loading={responseData.isLoading}
    //       onPaginationChange={onPaginationChange}
    //       total={totalRecords}
    //       current={currentPage}
    //     />
    //   ),
    // },
    {
      key: "deactivated",
      label: "Deactivated Users",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={deactivatedUsersData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
    form.resetFields();
    if (key !== "deactivated") {
      getRequest(
        null,
        API_METHODS.GET,
        `/config/user?pageNumber=${1}&usertype=${key}`
      );
      getUsersForDropdown(
        null,
        API_METHODS.GET,
        `/config/user/dropdown?usertype=${key}`
      );
    } else {
      setDropdownUsers([]);
      getDeactivatedUsers();
    }
  };

  return (
    <Card title="Users">
      <Form form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="userId" label={<strong>Users</strong>}>
              <Select
                placeholder="Select User"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {dropdownUsers.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.display_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Space>
              <Button type="primary" onClick={filterResults}>
                Search
              </Button>
              <Button type="primary" onClick={resetResult}>
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Tabs
        defaultActiveKey="users"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        tabBarExtraContent={
          <Button type="primary" onClick={handleAddUser}>
            Add User
          </Button>
        }
      />

      {visible && (
        <AddUser visible={visible} close={handleAddUser} edit={false} />
      )}
      {visibleEdit && (
        <AddUser
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditUser}
          edit={true}
        />
      )}
      {visibleRewards && (
        <Modal
          title="Rewards"
          open={visibleRewards}
          footer={null}
          onCancel={closeRewardModal}
          width={700}
          destroyOnClose={true}
        >
          <Space direction="vertical" style={{ width: "100%" }} size={24}>
            <Row gutter={16}>
              <Col span={8}>Reward Points - {points}</Col>
              <Col span={16}>
                <Space.Compact
                  style={{
                    width: "100%",
                  }}
                >
                  <Input
                    placeholder="Enter points to be redeemed"
                    onChange={(e) => setRedeemPoints(e.target.value)}
                  />
                  <Button
                    type="primary"
                    onClick={handleRedeem}
                    disabled={points == 0}
                  >
                    Redeem
                  </Button>
                </Space.Compact>
              </Col>
            </Row>
          </Space>
        </Modal>
      )}
      {visibleUpdatePassword && (
        <Modal
          title={`Update Password for- ${selectedRecord.username}`}
          open={visibleUpdatePassword}
          onOk={updatePassword}
          onCancel={handleCancelUpdatePassword}
          width={800}
          okText="Update Password"
          destroyOnClose={true}
        >
          <Form layout="vertical" form={form}>
            <Row>
              <Col offset={4} span={12}>
                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[
                    { required: true, message: "New Password is required." },
                    {
                      max: 20,
                      message:
                        "New Password should be maximum of 20 characters.",
                    },
                  ]}
                >
                  <Input placeholder="Password" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {showDelete && <Modal
        title="Delete User"
        open={showDelete}
        onOk={() => deleteOk(selectedRecord)}
        trigger={"hover"}
        okButtonProps={{
          loading: confirmDeletePCLoading,
        }}
        placement="rightTop"
        onCancel={() => setShowDelete(false)}
      >
        <>
          Are you sure to delete <b>{selectedRecord.displayName}?</b>
          <Form layout="vertical" form={form}>
            <Form.Item
              name="date"
              label="Deleted Date"
              rules={[
                {
                  required: true,
                  message: "Date is required.",
                },
              ]}
            >
              <DatePicker
                allowClear
                format={"DD-MM-YYYY"}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Form>
        </>
      </Modal>}
    </Card>
  );
}

export default UserTable;
