import { Button, Card, Col, DatePicker, Form, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import TableComponent from "../../common/table/TableComponent";
import { disableFutureDates, getClientConfig, getDataFromLocalStorage, setDataToLocalStorage } from "../../../generalFunctions";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

function SupervisorVisitFormTable() {
    const { supervisor, site } = getClientConfig();
    const navigate = useNavigate();
    const columns = [
        {
            title: "Site Id",
            dataIndex: "siteId",
        },
        {
            title: "Filled By",
            dataIndex: "filledByName",
        },
        {
            title: "Submitted At",
            dataIndex: "submitTimestamp",
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Button size="small" onClick={() => navigate(`/portal/admin/supervisor-visit-forms/${record.id}`)}>
                    View Details
                </Button>
            ),
        },
    ];

    const [responseData, getRequest] = useApi(
        `/admin/supervisor-visit-form?pageNumber=${1}`,
        null,
        API_METHODS.GET
    );

    const [tableData, setTableData] = useState();
    const [form] = Form.useForm();
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
         let filters = getDataFromLocalStorage("SupervisorVisitForm");
         let url = `/admin/supervisor-visit-form?pageNumber=${1}`;
         if (filters) {
           if (filters.date) {
             filters.date = dayjs(filters.date);
           }
           form.setFieldsValue(filters);
           url = filters.supervisorId ? url + `&filledBy=${filters.supervisorId}` : url;
           url = filters.siteId
             ? url + `&dbTableSiteId=${filters.siteId}`
             : url;
           url = filters.date
             ? url + `&date=${dayjs(filters.date).format("YYYY-MM-DD")}`
             : url;
         }
         getRequest(null, API_METHODS.GET, url);
    }, []);

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        const pagination =
            responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
        setTableData(data);
        setTotalRecords(pagination ? pagination.count : 0);
    }, [responseData]);

    const onPaginationChange = (page) => {
        let url = `/admin/supervisor-visit-form?pageNumber=${page.current}`;
        const values = form.getFieldsValue();
        url = values.supervisorId
          ? url + `&filledBy=${values.supervisorId}`
          : url;
        url = values.siteId ? url + `&dbTableSiteId=${values.siteId}` : url;
        url = values.date
            ? url + `&date=${dayjs(values.date).format("YYYY-MM-DD")}`
            : url;
        getRequest(null, API_METHODS.GET, url);
        setCurrentPage(page.current);
    };

    const filterResults = () => {
        const values = form.getFieldsValue();
        let url = `/admin/supervisor-visit-form?pageNumber=${1}`;
        url = values.supervisorId
          ? url + `&filledBy=${values.supervisorId}`
          : url;
        url = values.siteId ? url + `&dbTableSiteId=${values.siteId}` : url;
        url = values.date
            ? url + `&date=${dayjs(values.date).format("YYYY-MM-DD")}`
            : url;
        setCurrentPage(1);
        setDataToLocalStorage("SupervisorVisitForm", values);
        getRequest(null, API_METHODS.GET, url);
    };

    return (
        <>
            <Card title="Supervisor Visit Forms">
                <Form form={form}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="supervisorId"
                                label={<strong>Supervisors</strong>}
                            >
                                <Select
                                    placeholder="Select Supervisor"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {supervisor.map((option) => (
                                        <Select.Option key={option.id} value={option.id}>
                                            {option.displayName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="siteId" label={<strong>Sites</strong>}>
                                <Select
                                    placeholder="Select Site"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {site.map((option) => (
                                        <Select.Option key={option.id} value={option.id}>
                                            {option.siteId}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="date" label={<strong>Date</strong>}>
                                <DatePicker allowClear format={"DD-MM-YYYY"} disabledDate={disableFutureDates}/>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Space>
                                <Button type="primary" onClick={filterResults}>
                                    Search
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        form.resetFields();
                                        getRequest();
                                        setCurrentPage(1);
                                        localStorage.removeItem("SupervisorVisitForm");
                                    }}
                                >
                                    Reset
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <TableComponent
                    columns={columns}
                    tableData={tableData}
                    loading={responseData.isLoading}
                    onPaginationChange={onPaginationChange}
                    total={totalRecords}
                    current={currentPage}
                />
            </Card>
        </>
    );
}

export default SupervisorVisitFormTable;