import { Button, Card, Form, message, Popconfirm, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import AddVendor from "./AddVendor";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { setDataToLocalStorage } from "../../../generalFunctions";

function VendorTable() {
  const [activeTab, setActiveTab] = useState("vendors");
  const [confirmDeletePCLoading, setConfirmDeletePCLoading] = useState(false);
  const [confirmReactivatePCLoading, setConfirmReactivatePCLoading] =
    useState(false);

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {activeTab !== "deactivated" && (
            <Space>
              <Button size="small" onClick={() => editRecord(record)}>
                <EditOutlined />
              </Button>
              <Popconfirm
                title="Delete Vendor"
                description={`Are you sure to delete ${record.vendorName}?`}
                onConfirm={() => deleteOk(record)}
                trigger={"click"}
                okButtonProps={{
                  loading: confirmDeletePCLoading,
                }}
                placement="rightTop"
              >
                <Button size="small">
                  <DeleteOutlined style={{ color: "red" }} />
                </Button>
              </Popconfirm>
            </Space>
          )}
          {activeTab === "deactivated" && (
            <Space>
              <Popconfirm
                title="Reactivate Vendor"
                description={`Are you sure to reactivate ${record.vendorName}?`}
                onConfirm={() => reactivateRecord(record)}
                trigger={"click"}
                okButtonProps={{
                  loading: confirmReactivatePCLoading,
                }}
                placement="rightTop"
              >
                <Button size="small">Reactivate</Button>
              </Popconfirm>
            </Space>
          )}
        </>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/config/vendor?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );

  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteResponse, deleteRequest] = useApi();

  const [form] = Form.useForm();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientConfig, getClientConfig] = useApi(
    "/config",
    null,
    API_METHODS.GET
  );

  const [deactivatedVendorsData, getDeactivatedVendors] = useApi(
    `/config/vendor/deactivated?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [reactivateResponse, reactivateRequest] = useApi();

  const refreshClientConfig = () => {
    getClientConfig();
  };

  useEffect(() => {
    if (clientConfig.data) {
      setDataToLocalStorage("clientConfig", clientConfig.data);
    }
  }, [clientConfig]);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    const pagination =
      responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [responseData]);

  useEffect(() => {
    const data =
      deactivatedVendorsData.data !== BLANK_VALUE
        ? deactivatedVendorsData.data
        : [];
    const pagination =
      deactivatedVendorsData.pagination !== BLANK_VALUE
        ? deactivatedVendorsData.pagination
        : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [deactivatedVendorsData]);

  useEffect(() => {
    if (deleteResponse.data && !deleteResponse.isLoading) {
      getRequest();
      refreshClientConfig();
      setActiveTab("vendors");
      setConfirmDeletePCLoading(false);
      message.success("Vendor Deleted successfully.");
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (reactivateResponse.data && !reactivateResponse.isLoading) {
      getRequest();
      refreshClientConfig();
      setActiveTab("vendors");
      setConfirmReactivatePCLoading(false);
      message.success("Vendor Reactivated successfully.");
    }
  }, [reactivateResponse]);

  const handleAddVendor = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
      setActiveTab("vendors");
      refreshClientConfig();
    }
  };

  const handleEditVendor = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest();
      setActiveTab("vendors");
      refreshClientConfig();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  const deleteOk = (record) => {
    setConfirmDeletePCLoading(true);
    deleteRequest(null, API_METHODS.DELETE, `/config/vendor/${record.id}`);
  };
  const reactivateRecord = (record) => {
    setConfirmReactivatePCLoading(true);
    reactivateRequest(
      null,
      API_METHODS.POST,
      `/config/vendor/reactivate/${record.id}`
    );
  };

  const onPaginationChange = (page) => {
    getRequest(
      null,
      API_METHODS.GET,
      `/config/vendor?pageNumber=${page.current}`
    );
    setCurrentPage(page.current);
  };

  const onTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
    if (key !== "deactivated") {
      getRequest(null, API_METHODS.GET, `/config/vendor?pageNumber=${1}`);
    } else {
      getDeactivatedVendors();
    }
  };

  const items = [
    {
      key: "vendors",
      label: "Vendors",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "deactivated",
      label: "Deactivated Vendors",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={deactivatedVendorsData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  return (
    <Card title="Vendors">
      <Tabs
        defaultActiveKey="vendors"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        tabBarExtraContent={
          <Button type="primary" onClick={handleAddVendor}>
            Add Vendor
          </Button>
        }
      />

      {visible && (
        <AddVendor visible={visible} close={handleAddVendor} edit={false} />
      )}
      {visibleEdit && (
        <AddVendor
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditVendor}
          edit={true}
        />
      )}
    </Card>
  );
}

export default VendorTable;
