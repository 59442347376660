import { Button, Card, Col, DatePicker, Form, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import {
  API_METHODS,
  BLANK_VALUE,
  DEFAULT_DATE_FORMAT,
  PORTAL_URL,
} from "../../constants";
import TableComponent from "../common/table/TableComponent";
import dayjs from "dayjs";
import { disableFutureDates } from "../../generalFunctions";

function MainReportDateRange() {
  const columns = [
    {
      title: "Site Id",
      dataIndex: "siteId",
    },
    {
      title: "Atm Id",
      dataIndex: "atmId",
    },
    {
      title: "Site Type",
      dataIndex: "siteType",
    },
    {
      title: "RA Name",
      dataIndex: "raName",
    },
    {
      title: "MSP",
      dataIndex: "msp",
    },
    {
      title: "Date Of Visit",
      dataIndex: "dateOfVisit",
    },
    {
      title: "Time Of Visit",
      dataIndex: "timeOfVisit",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
    },
  ];

    let reportHeaders = [
      [
        "Site Id",
        "Site Type",
        "ATM Id",
        "MSP",
        "RA Name",
        "Contact Number",
        "Address",
        "Date Of Visit",
        "Time Of Visit",
        "ATM",
        "AC 1",
        "AC 2",
        "BackRoom Ac",
        "Lobby Lights",
        "Dustbin",
        "Doormat",
        "Lollipop",
        "Signage Board",
        "Unwanted Backroom",
        "Deep Cleaning",
        "CCTV",
        "Panic switch",
        "Hooter Speaker Mic",
        "Loose Wires",
        "Camera Functionality",
        "Main Door",
        "Interior Of Room",
        "Notice Board",
        "Remarks",
        "Images",
      ],
    ];

  const [responseData, getRequest] = useApi(
    "/admin/reports/main-report-date-range",
    null,
    API_METHODS.POST
  );
  const [tableData, setTableData] = useState();
  const [form] = Form.useForm();
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    const pagination =
      responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
    data?.forEach((element) => {
      element.id = {
        t: "s",
        v: "View Images",
        l: {
          Target: `${PORTAL_URL}/form-images/${element.id}`,
        },
      };
    });
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [responseData]);

  const getMainReport = () => {
    form
      .validateFields()
      .then((values) => {
        getRequest(values);
      })
      .catch(() => {});
  };

  return (
    <Card title="Main Report">
      <Form form={form} initialValues={{ date: dayjs().subtract(1, "D") }}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="startDate"
              label={<strong>From Date</strong>}
              rules={[{ required: true }]}
            >
              <DatePicker
                allowClear
                format={"DD-MM-YYYY"}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="endDate"
              label={<strong>To Date</strong>}
              rules={[{ required: true }]}
            >
              <DatePicker
                allowClear
                format={"DD-MM-YYYY"}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Space>
              <Button type="primary" onClick={getMainReport}>
                Search
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form.resetFields();
                }}
              >
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        clientSideRender={true}
        total={totalRecords}
        showDownloadOption={true}
        reportHeaders={reportHeaders}
        reportData={tableData}
        exportFileName={
          "RawReport-" +
          form.getFieldValue("startDate")?.format(DEFAULT_DATE_FORMAT) +
          "-To-" +
          form.getFieldValue("endDate")?.format(DEFAULT_DATE_FORMAT)
        }
      />
    </Card>
  );
}

export default MainReportDateRange;
