import { Button, Card, Col, DatePicker, Form, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE, DEFAULT_DATE_FORMAT } from "../../constants";
import TableComponent from "../common/table/TableComponent";
import { disableFutureDates, getClientConfig, replaceEmpty } from "../../generalFunctions";
import dayjs from "dayjs";

function RewardsStatement() {
    const { ra } = getClientConfig();
    const columns = [
        {
            title: "Awarded or Redeemed By",
            dataIndex: "actionedBy",
        },
        {
            title: "Awarded",
            dataIndex: "awardedPoints",
        },
        {
            title: "Redeemed",
            dataIndex: "redeemedPoints",
        },
        {
            title: "Date/Time",
            dataIndex: "timestamp",
        },
    ];

   let reportHeaders = [["Awarded or Redeemed By","Awarded","Redeemed","Date/Time"]];

    const [responseData, getRequest] = useApi();

    const [tableData, setTableData] = useState();
    const [reportData, setReportData] = useState();
    const [currentUrl, setCurrentUrl] = useState();
    const [form] = Form.useForm();
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
     const [raName, setRaName] = useState("");

    useEffect(() => {
        let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        const pagination =
            responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
        data = replaceEmpty(data);
        setTableData(data);
        setReportData(data);
        setTotalRecords(pagination ? pagination.count : 0);
    }, [responseData]);

    const onPaginationChange = (page) => {
        getRequest(
            null,
            API_METHODS.GET,
            `${currentUrl}?pageNumber=${page.current}`
        );
        setCurrentPage(page.current);
    };

    const getRewardStatement = () => {
        form.validateFields().then(values => {
            if (values.raId) {
                let url = `/rewards/statement/${values.raId}?pageNumber=${1}`;
                setCurrentUrl(url);
                getRequest(values, API_METHODS.POST, url);
            }
        }).catch(() => { });
    }

    const raSelected =(raId)=>{
      let raName = ra.find((user) => user.id == raId).displayName;
     
       setRaName(raName);
    }
    
    return (
        <Card title="Rewards Statement">
            <Form form={form} initialValues={{ "startDate": dayjs().subtract(1, "M"), "endDate": dayjs() }}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            name="raId"
                            label={<strong>Roaming  Attendants</strong>}
                            rules={[{ required: true }]}
                        >
                            <Select
                                placeholder="Select Roaming  Attendant"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                onSelect={raSelected}
                            >
                                {ra.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.displayName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="startDate"
                            label={<strong>From Date</strong>}
                            rules={[{ required: true }]}
                        >
                            <DatePicker
                                allowClear
                                format={"DD-MM-YYYY"}
                                disabledDate={disableFutureDates}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="endDate"
                            label={<strong>To Date</strong>}
                            rules={[{ required: true }]}
                        >
                            <DatePicker
                                allowClear
                                format={"DD-MM-YYYY"}
                                disabledDate={disableFutureDates}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space>
                            <Button type="primary" onClick={getRewardStatement}>Search</Button>
                            <Button type="primary" onClick={() => { form.resetFields(); }}>Reset</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <TableComponent
                columns={columns}
                tableData={tableData}
                loading={responseData.isLoading}
                onPaginationChange={onPaginationChange}
                total={totalRecords}
                current={currentPage}
                showDownloadOption={true}
                exportFileName={"RewardStatement-" +raName + "-" +
                form.getFieldValue("startDate")?.format(DEFAULT_DATE_FORMAT) +
                "-To-" + form.getFieldValue("endDate")?.format(DEFAULT_DATE_FORMAT)}
                reportHeaders = {reportHeaders}
                reportData = {reportData}
            />
        </Card>
    );
}

export default RewardsStatement;
