import {
  Button,
  Card,
  Col,
  Form,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../../constants";
import useApi from "../../../../hooks/useApi";
import TableComponent from "../../../common/table/TableComponent";
import AddSupervisorToRA from "./AddSupervisorToRA";
import { DeleteOutlined } from "@ant-design/icons";
import {
  setDataToLocalStorage,
  getClientConfig as config,
} from "../../../../generalFunctions";

function SupervisorRATable() {
  const { ra, supervisor } = config();
  const [activeTab, setActiveTab] = useState("active");
  const [confirmDeletePCLoading, setConfirmDeletePCLoading] = useState(false);
  const [confirmReactivatePCLoading, setConfirmReactivatePCLoading] =
    useState(false);

  const columns = [
    {
      title: "Supervisor Name",
      dataIndex: "supervisorDisplayName",
    },
    {
      title: "Roaming  Attendant Name",
      dataIndex: "raDisplayName",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {activeTab !== "deactivated" && (
            <Space>
              <Popconfirm
                title="Delete Relation"
                description={`Are you sure to delete this relation?`}
                onConfirm={() => deleteOk(record)}
                trigger={"click"}
                okButtonProps={{
                  loading: confirmDeletePCLoading,
                }}
                placement="rightTop"
              >
                <Button size="small">
                  <DeleteOutlined style={{ color: "red" }} />
                </Button>
              </Popconfirm>
            </Space>
          )}
          {activeTab === "deactivated" && (
            <Space>
              <Popconfirm
                title="Reactivate Relation"
                description={`Are you sure to reactivate this relation?`}
                onConfirm={() => reactivateRecord(record)}
                trigger={"click"}
                okButtonProps={{
                  loading: confirmReactivatePCLoading,
                }}
                placement="rightTop"
              >
                <Button size="small">Reactivate</Button>
              </Popconfirm>
            </Space>
          )}
        </>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/config/supervisor-ra?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );

  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [deleteResponse, deleteRequest] = useApi();

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientConfig, getClientConfig] = useApi(
    "/config",
    null,
    API_METHODS.GET
  );
  const [form] = Form.useForm();
  const [deactivatedMappingsData, getDeactivatedMappings] = useApi(
    `/config/supervisor-ra/deactivated?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [reactivateResponse, reactivateRequest] = useApi();

  const refreshClientConfig = () => {
    getClientConfig();
  };

  useEffect(() => {
    if (clientConfig.data) {
      setDataToLocalStorage("clientConfig", clientConfig.data);
    }
  }, [clientConfig]);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    const pagination =
      responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [responseData]);

  useEffect(() => {
    const data =
      deactivatedMappingsData.data !== BLANK_VALUE
        ? deactivatedMappingsData.data
        : [];
    const pagination =
      deactivatedMappingsData.pagination !== BLANK_VALUE
        ? deactivatedMappingsData.pagination
        : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [deactivatedMappingsData]);

  useEffect(() => {
    if (deleteResponse.data && !deleteResponse.isLoading) {
      getRequest();
      refreshClientConfig();
      setConfirmDeletePCLoading(false);
      message.success("Relation Deleted successfully.");
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (reactivateResponse.data && !reactivateResponse.isLoading) {
      getRequest();
      refreshClientConfig();
      setActiveTab("active");
      setConfirmReactivatePCLoading({});
      message.success("Relation Reactivated successfully.");
    }
  }, [reactivateResponse]);

  const handleAddMapping = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
      setActiveTab("active");
      refreshClientConfig();
    }
  };

  const deleteOk = (record) => {
    setConfirmDeletePCLoading(true);
    deleteRequest(
      null,
      API_METHODS.DELETE,
      `/config/supervisor-ra/${record.id}`
    );
  };

  const reactivateRecord = (record) => {
    setConfirmReactivatePCLoading(true);
    reactivateRequest(
      null,
      API_METHODS.POST,
      `/config/supervisor-ra/reactivate/${record.id}`
    );
  };

  const onPaginationChange = (page) => {
    setCurrentPage(page.current);
    const values = form.getFieldsValue();
    let url = `/config/supervisor-ra?pageNumber=${page.current}`;
    if (activeTab === "deactivated") {
      url = `/config/supervisor-ra/deactivated?pageNumber=${page.current}`;
      url = values.raId ? url + `&raId=${values.raId}` : url;
      url = values.supervisorId
        ? url + `&supervisorId=${values.supervisorId}`
        : url;
      getDeactivatedMappings(null, API_METHODS.GET, url);
    } else {
      url = values.raId ? url + `&raId=${values.raId}` : url;
      url = values.supervisorId
        ? url + `&supervisorId=${values.supervisorId}`
        : url;
      getRequest(null, API_METHODS.GET, url);
    }
  };

  const onTabChange = (key) => {
    form.resetFields();
    setActiveTab(key);
    setCurrentPage(1);
    if (key === "deactivated") {
      getDeactivatedMappings();
    } else {
      getRequest();
    }
  };

  const filterResults = () => {
    const values = form.getFieldsValue();
    setCurrentPage(1);
    let url = `/config/supervisor-ra?pageNumber=${1}`;
    if (activeTab === "deactivated") {
      url = `/config/supervisor-ra/deactivated?pageNumber=${1}`;
      url = values.raId ? url + `&raId=${values.raId}` : url;
      url = values.supervisorId
        ? url + `&supervisorId=${values.supervisorId}`
        : url;
      getDeactivatedMappings(null, API_METHODS.GET, url);
    } else {
      url = values.raId ? url + `&raId=${values.raId}` : url;
      url = values.supervisorId
        ? url + `&supervisorId=${values.supervisorId}`
        : url;
      getRequest(null, API_METHODS.GET, url);
    }
  };

  const resetResult = () => {
    setCurrentPage(1);
    form.resetFields();
    if (activeTab === "deactivated") {
      getDeactivatedMappings();
    } else {
      getRequest();
    }
  };

  const items = [
    {
      key: "active",
      label: "Supervisor RA Relation",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "deactivated",
      label: "Deactivated Supervisor RA Relation",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={deactivatedMappingsData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  return (
    <Card title="Supervisor RA Relation">
      <Form form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="supervisorId" label={<strong>Supervisors</strong>}>
              <Select
                placeholder="Select Supervisor"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {supervisor.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="raId" label={<strong>Roaming Attendants</strong>}>
              <Select
                placeholder="Select Roaming Attendant"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {ra.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Space>
              <Button type="primary" onClick={filterResults}>
                Search
              </Button>
              <Button type="primary" onClick={resetResult}>
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Tabs
        defaultActiveKey="active"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        tabBarExtraContent={
          <Button type="primary" onClick={handleAddMapping}>
            Add Relation
          </Button>
        }
      />

      {visible && (
        <AddSupervisorToRA
          visible={visible}
          close={handleAddMapping}
          edit={false}
        />
      )}
    </Card>
  );
}

export default SupervisorRATable;
