import { Button, Card, Col, DatePicker, Form, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import TableComponent from "../common/table/TableComponent";
import dayjs from "dayjs";
import { disableFutureDates, replaceEmpty } from "../../generalFunctions";

function DeepCleaningReport() {
  const columns = [
    {
      title: "Site Id",
      dataIndex: "site_id",
    },
    {
      title: "RA Name",
      dataIndex: "ra_name",
    },
    {
      title: "Timestamp",
      dataIndex: "submit_timestamp",
    },
  ];

  const [responseData, getRequest] = useApi(
    "/admin/reports/deep-cleaning",
    null,
    API_METHODS.POST
  );

  const [tableData, setTableData] = useState();
  const [form] = Form.useForm();

  let reportHeaders = [["Site Id", "RA Name", "Timestamp"]];

  useEffect(() => {
    let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    data = replaceEmpty(data);
    setTableData(data);
  }, [responseData]);

  const getReportData = () => {
    form
      .validateFields()
      .then((values) => {
        let requestBody = {};
        requestBody.month = dayjs(values.date).month() + 1;
        requestBody.year = dayjs(values.date).year();
        getRequest(requestBody);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Card title="Deep Cleaning Report">
      <Form form={form}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="date"
              label={<strong>For Month</strong>}
              rules={[{ required: true }]}
            >
              <DatePicker
                allowClear
                picker="month"
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Space>
              <Button type="primary" onClick={getReportData}>
                Search
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form.resetFields();
                }}
              >
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        clientSideRender={true}
        showDownloadOption={true}
        reportHeaders={reportHeaders}
        reportData={tableData}
        exportFileName={
          "Deep Cleaning-" + form.getFieldValue("date")?.format("MM-YYYY")
        }
      />
    </Card>
  );
}

export default DeepCleaningReport;
