import React, { useEffect } from "react";
import { Modal, Form, Input, Col, Row, message, Select } from "antd";
import { API_METHODS } from "../../../constants";
import useApi from "../../../hooks/useApi";

function AddVendor(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord, edit } = props;

  const [response, request] = useApi("/config/vendor", null, API_METHODS.POST);
  const [editResponse, editRequest] = useApi(
    `/config/vendor/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Vendor added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Vendor updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedRecord) {
          editRequest(values);
        } else {
          request(values, API_METHODS.POST);
        }
      })
      .catch((e) => {});;
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title={selectedRecord ? "Update Vendor" : "Add Vendor"}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="vendorName"
              label="Vendor Name"
              rules={[
                { required: true, message: "Vendor Name is required." },
                {
                  max: 100,
                  message: "Vendor Name should be maximum of 100 characters.",
                },
              ]}
            >
              <Input placeholder="Vendor Name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddVendor;
