import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_METHODS, BLANK_VALUE } from '../../../constants';
import { Card, Skeleton, Image, Space } from 'antd';
import useApi from '../../../hooks/useApi';

const FormImagesView = (props) => {
    const { formId } = useParams();
    const [images, setImages] = useState();
    const [responseData, getRequest] = useApi(
        `/images/${formId}`,
        null,
        API_METHODS.GET
    );

    useEffect(() => {
        getRequest();
    }, []);

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        setImages(data);
    }, [responseData]);


    return (<Card title="Form Images">
        {images ? <Space direction='vertical' style={{ width: "100%" }}>{Object.keys(images)?.map(element =>
            <Card title={element?.replace('_', ' ').toUpperCase()}>
                <Space>
                    {images[element].map(image => <Image
                        width={100}
                        height={100}
                        src={image}
                        style={{ marginLeft: 10 }}
                        placeholder={
                            <Image preview={false} src={image} width={100} style={{ marginLeft: 10 }} />
                        }
                    />)}
                </Space>
            </Card>
        )}</Space>
            : <Skeleton />}
    </Card>);
}

export default FormImagesView;