import React, { useEffect } from "react";
import { Modal, Form, Col, Row, message, Upload, Button } from "antd";
import { API_METHODS } from "../../../constants";
import useApi from "../../../hooks/useApi";
import { UploadOutlined } from "@ant-design/icons";
function AddSites(props) {
  const [form] = Form.useForm();
  const { visible, close } = props;

  const [response, request] = useApi(
    "/config/site",
    null,
    API_METHODS.POST,
    true
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Sites added successfully.");
    }
  }, [response]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      const formData = new FormData();
      for (const name in values) {
        if (name === "attachment") {
          if (values["attachment"] !== undefined) {
            formData.append(
              "sites",
              values["attachment"].file.originFileObj,
              values["attachment"].file.name
            );
          }
        }
      }
      request(formData);
    });
  };

  const handleCancel = () => {
    close();
  };

  const uploadProps = {
    beforeUpload: (file) => {
      //   const isImage = file.type === "image/png" || file.type === "image/jpeg";
      //   if (!isImage) {
      //     message.error(`${file.name} error. Only PNG and JPEG files supported`);
      //   }
      //   return isImage || Upload.LIST_IGNORE;
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Modal
      title={"Upload Sites"}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="attachment"
              label="Upload Sites"
              rules={[{ required: true, message: "File is required." }]}
            >
              <Upload
                customRequest={dummyRequest}
                {...uploadProps}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddSites;
