import { BLANK_VALUE } from "./constants";
import imageCompression from "browser-image-compression";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js"; // Import UTC plugin
import timezone from "dayjs/plugin/timezone.js"; // Import UTC plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Kolkata");

export function replaceNull(jsonObject, replaceValue = BLANK_VALUE) {
  const replacedObject = (key, value) =>
    String(value) === "null" || String(value) === "undefined" || value === ""
      ? replaceValue
      : value;
  return JSON.parse(JSON.stringify(jsonObject, replacedObject));
}

export function replaceEmpty(jsonObject, replaceValue = "") {
  const replacedObject = (key, value) =>
    String(value) === BLANK_VALUE ? replaceValue : value;
  return JSON.parse(JSON.stringify(jsonObject, replacedObject));
}

export const setDataToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const getClientConfig = () => {
  return JSON.parse(localStorage.getItem("clientConfig"));
};

export const isEmpty = (value) => {
  if (
    value === null ||
    value === undefined ||
    value === BLANK_VALUE ||
    value === ""
  ) {
    return true;
  }
  return false;
};

export const getMonthFromNumber = (monthNumber) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[monthNumber];
};

export async function handleImageUpload(event) {
  const imageFile = event;
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;

    // await uploadToServer(compressedFile); // write your own logic
  } catch (error) {
    console.log(error);
  }
}

export const disableFutureDates = (current) => {
  return current && current.valueOf() > dayjs().tz("Asia/Kolkata").endOf("day");
};
