import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Input, List, Radio, Row, Space, Upload, message } from 'antd';
import { API_METHODS, BLANK_VALUE } from '../../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../hooks/useApi';
import { UploadOutlined } from "@ant-design/icons";
import { isEmpty } from '../../../generalFunctions';
import { useImageDownloadZip } from '../../../hooks/useImageDownloadZip';

const UpdateSiteVisitForm = (props) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { handleZip } = useImageDownloadZip();
    const [siteVisitDetails, setSiteVisitDetails] = useState();
    const [dummyState, setDummyState] = useState();
    const { visitFormId } = useParams();
    const [responseData, getRequest] = useApi(
        `/mis/visit-form/${visitFormId}`,
        null,
        API_METHODS.GET
    );
    const [updateResponseData, updateRequest] = useApi(
        `/mis/visit-form/update/${visitFormId}`,
        null,
        API_METHODS.PUT,
        true
    );

    const updateSiteVisitForm = () => {
        form.validateFields().then((values, fileList) => {
            let newAnswers = [];
            let newImages = [];
            let questionIds = Object.keys(values);
            for (let i = 0; i < questionIds.length; i++) {
                if (!questionIds[i].toString().includes("images") && questionIds[i] !== "newSiteImages" && questionIds[i] !== "theftComment"  && questionIds[i] !== "remarks") {
                    const questionId = questionIds[i];

                    //remove images if selected answer has isImageSelectable=false 
                    const currentQuestion = siteVisitDetails.currentQuestionAnswers.find(a => a.questionId == questionId);
                    const isImageSelectable = currentQuestion?.options?.find(o => o.id === values[questionId])?.isImageSelectable;

                    //remove subquestions if selected answer has good=false 
                    if (currentQuestion?.subquestions?.length > 0) {
                        const isIncludeSubquestion = currentQuestion.options?.find(o => o.id === values[questionId])?.good;
                        if (!isIncludeSubquestion) {
                            const subquestionIds = currentQuestion.subquestions.map(s => s.questionId);
                            subquestionIds.forEach(sqId => {
                                questionIds = questionIds.filter(qId => qId !== sqId);
                            });
                        } else {

                        }
                    }

                    const answerData = {
                        "images": isImageSelectable ? siteVisitDetails.answers.find(a => a.questionId == questionId)?.images : [],
                        "answerId": values[questionId],
                        "questionId": questionId
                    }

                    //for Theft Incident = Other
                    if (questionId === "29" && values.theftComment && values[questionId] === 6) {
                        answerData.remarks = values.theftComment;
                    }

                    newAnswers.push(answerData);
                    if (values["images" + questionId]) {
                        const newImagesArray = (values["images" + questionId].fileList.map(f => { return { questionId: questionId, originFileObj: f.originFileObj, name: f.name, } }));
                        newImages = [...newImages, ...newImagesArray];
                    }
                }
            };

            const formData = new FormData();
            for (let i = 0; i < newImages.length; i++) {
                formData.append(
                    newImages[i].questionId,
                    newImages[i].originFileObj,
                    newImages[i].name,
                );
            }

            let newSiteImages = values.newSiteImages?.fileList.map(f => { return { originFileObj: f.originFileObj, name: f.name, } })
            for (let i = 0; i < newSiteImages?.length; i++) {
                formData.append(
                    "newSiteImages",
                    newSiteImages[i].originFileObj,
                    newSiteImages[i].name,
                );
            }
            formData.append("oldSiteImages", JSON.stringify(siteVisitDetails.site_images));
            formData.append("answers", JSON.stringify(newAnswers));
            formData.append("remarks", values.remarks);
            updateRequest(formData);
        }).catch(e => console.log(e));
    }

    const uploadProps = {
        beforeUpload: (file) => {
            const isImage = file.type === "image/png" || file.type === "image/jpeg";
            if (!isImage) {
                message.error(`${file.name} error. Only PNG and JPEG files supported`);
            }
            return isImage || Upload.LIST_IGNORE;
        },
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleAnswerChange = (e) => {
        setDummyState(e);
    }

    const deleteImage = (record, image, parentQuestion = false) => {
        let newSiteVisitDetails = { ...siteVisitDetails };
        let modifiedAnswer;
        if (parentQuestion) {
            modifiedAnswer = parentQuestion.subquestions.find(a => a.questionId == record.questionId);
        } else {
            modifiedAnswer = newSiteVisitDetails.currentQuestionAnswers.find(a => a.questionId == record.questionId);
        }
        const remainingImages = modifiedAnswer?.images.filter(i => i !== image);
        // if (remainingImages.length > 0) {
        modifiedAnswer.images = remainingImages;
        setSiteVisitDetails(newSiteVisitDetails);
        // } else {
        //     message.warning("Atleast one image is required.");
        // }
    }

    const deleteSiteImage = (image) => {
        let newSiteVisitDetails = { ...siteVisitDetails };
        const newSiteImages = form.getFieldValue("newSiteImages");
        const remainingImages = newSiteVisitDetails.site_images.filter(i => i !== image);
        // console.log(remainingImages.length + newSiteImages?.fileList.length, newSiteImages);
        // if ((remainingImages.length + newSiteImages?.fileList.length) > 4) {
        newSiteVisitDetails.site_images = remainingImages;
        setSiteVisitDetails(newSiteVisitDetails);
        // } else {
        //     message.warning("Atleast four images is required.");
        // }
    }

    const downloadImages = () => {
        const zipFileName = "Site Visit Form - " + siteVisitDetails?.site_id + " by " + (siteVisitDetails?.filled_by_name.toUpperCase()) + " @ " + siteVisitDetails?.submit_timestamp;
        let images = [];
        images.push(siteVisitDetails.start_selfie);
        siteVisitDetails.currentQuestionAnswers.forEach(item => {
            if (item.images?.length > 0) {
                const imagesData = item.images.map(item => item);
                images = [...images, ...imagesData];
            }
            if (item.subquestions) {
                item.subquestions.forEach(subQuestion => {
                    if (subQuestion.images?.length > 0) {
                        const imagesData = subQuestion.images.map((item) => item);
                        images = [...images, ...imagesData];
                    }
                })
            }
        })
        images.push(...siteVisitDetails.site_images);
        handleZip(images, zipFileName);
    }

    useEffect(() => {
        if (!responseData.isLoading) {
            let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
            if (data) {
                let initialValues = {};
                let currentQuestionAnswers = [];
                let initialQuestionAnswers = [];
                data[0].questions.forEach(q => {
                    let questionWithAnswer = { ...q };
                    let answer = data[0].answers.find(a => a.questionId == questionWithAnswer.questionId);
                    questionWithAnswer.answerId = answer.answerId;
                    questionWithAnswer.images = answer.images;
                    currentQuestionAnswers.push(questionWithAnswer);
                    initialQuestionAnswers.push(questionWithAnswer);

                    if (questionWithAnswer.questionId === 29 && answer.remarks) {
                        initialValues.theftComment = answer.remarks;
                    }

                    if (questionWithAnswer.subquestions?.length > 0) {
                        questionWithAnswer.subquestions.forEach(q => {
                            let subquestionWithAnswer = { ...q };
                            let answer = data[0].answers.find(a => a.questionId == subquestionWithAnswer.questionId);
                            if (answer) {
                                subquestionWithAnswer.answerId = answer.answerId;
                                q.images = answer.images;
                                initialQuestionAnswers.push(subquestionWithAnswer);
                            }
                        })
                    }
                });
                data[0].currentQuestionAnswers = currentQuestionAnswers;
                setSiteVisitDetails(data[0]);

                initialQuestionAnswers.forEach((question, index) => {
                    const initialAnswer = { [question.questionId]: parseInt(question.answerId) }
                    initialValues = { ...initialValues, ...initialAnswer };
                });
                form.setFieldsValue(initialValues);
                if(data[0].remarks){
                    form.setFieldValue("remarks",data[0].remarks)
                }
            }
        }
    }, [responseData]);

    useEffect(() => {
        if (updateResponseData.data && !updateResponseData.isLoading && isEmpty(updateResponseData.error)) {
            message.info("Site visit form updated successfully.");
            navigate(-1);
        } else if (updateResponseData.error && !updateResponseData.isLoading) {
            message.info(updateResponseData.error);
        }
    }, [updateResponseData]);

    useEffect(() => {
        getRequest();
    }, []);

    return (
        <Card loading={responseData.isLoading} title={"Site Visit Form - " + siteVisitDetails?.site_id + " by " + (siteVisitDetails?.filled_by_name.toUpperCase()) + " @ " + siteVisitDetails?.submit_timestamp}
            extra={<Button onClick={downloadImages}>Download Images</Button>}>
            {siteVisitDetails?.currentQuestionAnswers?.length > 0 &&
                <Form form={form} layout='vertical'>
                    <Card title={"Start Selfie @" + siteVisitDetails.start_time} style={{ marginBottom: 15 }}>
                        <Image
                            width={100}
                            height={100}
                            src={siteVisitDetails.start_selfie}
                            style={{ marginLeft: 10 }}
                            placeholder={
                                <Image preview={false} src={siteVisitDetails.start_selfie} width={100} style={{ marginLeft: 10 }} />
                            }
                        />
                    </Card>
                    <List
                        grid={{
                            gutter: 16,
                            column: 1
                        }}
                        dataSource={siteVisitDetails.currentQuestionAnswers}
                        renderItem={(item, index) => (
                            <List.Item>
                                <Card title={(index + 1) + ". " + item.questionHeading}>
                                    <Form.Item label="" name={item.questionId}>
                                        <Radio.Group key={item.questionId} onChange={handleAnswerChange}>
                                            <Space>
                                                {item.options.map(option =>
                                                    <Radio value={option.id}>{option.tittle}</Radio>
                                                )}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                    {(item.type === 3 && form.getFieldValue(29) === 6) && <Form.Item label="" name="theftComment" style={{ marginTop: 10 }}>
                                        <Input.TextArea rows={3} placeholder='Remarks' required />
                                    </Form.Item>}
                                    <Row>
                                        <Space>
                                            {item.images?.length > 0 && item.options.find(option => option.id === form.getFieldValue(item.questionId))?.isImageSelectable &&
                                                <Space>
                                                    {item.images.map(image => <Space direction='vertical' style={{ width: "100%" }}>
                                                        <Image
                                                            width={100}
                                                            height={100}
                                                            src={image}
                                                            style={{ marginLeft: 10 }}
                                                            placeholder={
                                                                <Image preview={false} src={image} width={100} style={{ marginLeft: 10 }} />
                                                            }
                                                        /><Button size='small' style={{ marginLeft: 10 }} onClick={() => deleteImage(item, image)}>delete</Button></Space>)}
                                                </Space>
                                            }
                                            {item.options.find(option => option.id === form.getFieldValue(item.questionId))?.isImageSelectable &&
                                                <Form.Item name={"images" + item.questionId} label="" style={{ marginLeft: 25 }}>
                                                    <Upload
                                                        {...uploadProps}
                                                        customRequest={dummyRequest}
                                                        listType="picture-card"
                                                        // fileList={fileList}
                                                        // onChange={handleChange}
                                                        multiple={true}
                                                    >
                                                        {
                                                            <UploadOutlined />
                                                        }
                                                    </Upload>
                                                </Form.Item>
                                            }
                                        </Space>
                                    </Row>
                                    <Row>
                                        {item.subquestions && item.options.find(option => option.id === form.getFieldValue(item.questionId))?.id == 1 && item.subquestions.map((subQuestion, index) =>
                                            <Col span={24} style={{ marginTop: 10 }}>
                                                <Card title={(index + 1) + ". " + subQuestion.questionHeading}>
                                                    <Form.Item label="" name={subQuestion.questionId} rules={[{
                                                        required: true
                                                    }]}>
                                                        <Radio.Group key={subQuestion.questionId} onChange={handleAnswerChange}>
                                                            <Space>
                                                                {subQuestion.options.map(option =>
                                                                    <Radio value={option.id}>{option.tittle}</Radio>
                                                                )}
                                                            </Space>
                                                        </Radio.Group>
                                                    </Form.Item>

                                                    <Row>
                                                        <Space>
                                                            {subQuestion.images?.length > 0 && subQuestion.options.find(option => option.id === form.getFieldValue(subQuestion.questionId))?.isImageSelectable &&
                                                                <Space>
                                                                    {subQuestion.images.map(subimage => <Space direction='vertical' style={{ width: "100%" }}><Image
                                                                        width={100}
                                                                        height={100}
                                                                        src={subimage}
                                                                        style={{ marginLeft: 10 }}
                                                                        placeholder={
                                                                            <Image preview={false} src={subimage} width={100} style={{ marginLeft: 10 }} />
                                                                        }
                                                                    /><Button size='small' style={{ marginLeft: 10 }} onClick={() => deleteImage(subQuestion, subimage, item)}>delete</Button></Space>)}
                                                                </Space>
                                                            }
                                                            {subQuestion.options.find(option => option.id === form.getFieldValue(subQuestion.questionId))?.isImageSelectable &&
                                                                <Form.Item name={"images" + subQuestion.questionId} label="" style={{ marginLeft: 25 }}>
                                                                    <Upload
                                                                        {...uploadProps}
                                                                        customRequest={dummyRequest}
                                                                        listType="picture-card"
                                                                        // fileList={fileList}
                                                                        // onChange={handleChange}
                                                                        multiple={true}
                                                                    >
                                                                        {
                                                                            <UploadOutlined />
                                                                        }
                                                                    </Upload>
                                                                </Form.Item>
                                                            }
                                                        </Space>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </Card>
                            </List.Item>

                        )}
                    />
                    <Card title={"Site Images"}>

                        <Col span={22}>
                            <Space>
                                {siteVisitDetails.site_images?.length > 0 && siteVisitDetails.site_images.map(image => <Space direction='vertical' style={{ width: "100%" }}>
                                    <Image
                                        width={100}
                                        height={100}
                                        src={image}
                                        style={{ marginLeft: 10 }}
                                        placeholder={
                                            <Image preview={false} src={image} width={100} style={{ marginLeft: 10 }} />
                                        }
                                    />
                                    <Button size='small' style={{ marginLeft: 10 }} onClick={() => deleteSiteImage(image)}>delete</Button></Space>)}
                                <Form.Item name={"newSiteImages"} label="" style={{ marginLeft: 25 }}>
                                    <Upload
                                        {...uploadProps}
                                        customRequest={dummyRequest}
                                        listType="picture-card"
                                        // fileList={fileList}
                                        // onChange={handleChange}
                                        multiple={true}
                                    >
                                        {
                                            <UploadOutlined />
                                        }
                                    </Upload>
                                </Form.Item>
                            </Space>
                        </Col>

                    </Card>
                    <Form.Item label="Remarks" name="remarks">
                        <Input.TextArea></Input.TextArea>
                    </Form.Item>
                </Form>
            }
            <Space style={{ float: "right" }}>
                <Button
                    type="default"
                    onClick={() => navigate(-1)}
                    disabled={updateResponseData.isLoading}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={updateSiteVisitForm}
                    loading={updateResponseData.isLoading}
                >
                    Update
                </Button>
            </Space>
        </Card >
    );
}

export default UpdateSiteVisitForm;