import { Button, Card, Col, DatePicker, Form, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE, DEFAULT_DATE_FORMAT } from "../../constants";
import TableComponent from "../common/table/TableComponent";
import dayjs from "dayjs";
import { disableFutureDates } from "../../generalFunctions";

function SummaryReport() {
    const columns = [
        {
            title: "Name",
            dataIndex: "raName",
        },
        {
            title: "First Visit",
            dataIndex: "firstVisit",
        },
        {
            title: "Second Visit",
            dataIndex: "secondVisit",
        },
        {
            title: "Third Visit",
            dataIndex: "thirdVisit",
        },
        {
            title: "Total Count",
            dataIndex: "totalCount",
        },
    ];
    const [responseData, getRequest] = useApi("/admin/reports/summary", null, API_METHODS.POST);
    const [tableData, setTableData] = useState();
    const [form] = Form.useForm();
    const [totalRecords, setTotalRecords] = useState(0);
   let reportHeaders = [
     ["Name", "First Visit", "Second Visit", "Third Visit","Total"],
   ];

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        const pagination =
            responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
        setTableData(data);
        setTotalRecords(pagination ? pagination.count : 0);
    }, [responseData]);


    const getSummaryReport = () => {
        form.validateFields().then(values => {
            values.date = dayjs(values.date).format("YYYY-MM-DD");
            getRequest(values);
        }).catch(() => { });
    }

    return (
        <Card title="Summary Report">
            <Form form={form} initialValues={{ "date": dayjs().subtract(1, "D") }}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            name="date"
                            label={<strong>For Date</strong>}
                            rules={[{ required: true }]}
                        >
                            <DatePicker
                                allowClear
                                format={"DD-MM-YYYY"}
                                 disabledDate={disableFutureDates}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space>
                            <Button type="primary" onClick={getSummaryReport}>Search</Button>
                            <Button type="primary" onClick={() => { form.resetFields(); }}>Reset</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <TableComponent
                columns={columns}
                tableData={tableData}
                loading={responseData.isLoading}
                clientSideRender={true}
                total={totalRecords}
                showDownloadOption={true}
                reportHeaders = {reportHeaders}
                reportData = {tableData}
                exportFileName={"SummaryReport-" + form.getFieldValue("date")?.format(DEFAULT_DATE_FORMAT)}
            />
        </Card>
    );
}

export default SummaryReport;
