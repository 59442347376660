import React, { useEffect } from "react";
import { Modal, Form, Input, Col, Row, message, Select } from "antd";
import { API_METHODS } from "../../../../constants";
import useApi from "../../../../hooks/useApi";
import { getClientConfig } from "../../../../generalFunctions";

function AddRAToSites(props) {
  const [form] = Form.useForm();
  const { visible, close } = props;

  const { ra, site } = getClientConfig();

  const [response, request] = useApi(
    "/config/ra-sites",
    null,
    API_METHODS.POST
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("RA Site relation added successfully.");
    }
  }, [response]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        request(values, API_METHODS.POST);
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title={"Add RA to Sites"}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="raId"
              label="Roaming  Attendant"
              rules={[
                { required: true, message: "Roaming  Attendant is required." },
              ]}
            >
              <Select
                placeholder="Select Roaming  Attendant"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {ra.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="siteIds"
              label="Sites"
              rules={[{ required: true, message: "Site is required." }]}
            >
              <Select
                placeholder="Select Site"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                mode="multiple"
              >
                {site.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.siteId}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddRAToSites;
