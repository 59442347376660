import { Card } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import TableComponent from "../common/table/TableComponent";
import { replaceEmpty } from "../../generalFunctions";
import dayjs from "dayjs";

function ACNonFunctionalReport() {
  const columns = [
    {
      title: "Site ID",
      dataIndex: "site_id",
    },
    {
      title: "MSP",
      dataIndex: "msp",
    },
    {
      title: "RA",
      dataIndex: "ra_name",
    },
  ];

  const [responseData, getRequest] = useApi(
    "/admin/reports/ac-off",
    null,
    API_METHODS.GET
  );

  const [tableData, setTableData] = useState();
  let reportHeaders = [["Site Id", "Site Type", "ATM Id", "MSP", "RA Name","City","State","Address","AC1 Working","AC1 Ageing","AC2 Working","AC2 Ageing","Backroom AC Working","Backroom AC ageing"]];

  useEffect(() => {
    let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    if (data?.length > 0) {
      data = replaceEmpty(data);
    }
    setTableData(data);
  }, [responseData]);

  useEffect(() => {
    getRequest();
  }, []);

  return (
    <Card title="AC Non Functional">
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        clientSideRender={true}
        showDownloadOption={true}
        reportHeaders={reportHeaders}
        reportData = {tableData}
        exportFileName={"Ac-Non-Functional-" + dayjs().format("DD-MM-YYYY")}
      />
    </Card>
  );
}

export default ACNonFunctionalReport;
