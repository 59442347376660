import { Button } from "antd";
import React from "react";
import * as XLSX from "xlsx";

function ExcelExport(props) {
  const { data, headers, reportName } = props;
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, headers);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });

    XLSX.utils.book_append_sheet(wb, ws, "sheet1");

    XLSX.writeFile(wb, reportName + ".xlsx");
  };

  return <Button style={{float:"right"}} type="primary" onClick={exportToExcel}>Download Report</Button>;
}

export default ExcelExport;
