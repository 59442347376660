import { Button, Card, Col, DatePicker, Form, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE, DEFAULT_DATE_FORMAT } from "../../constants";
import TableComponent from "../common/table/TableComponent";
import dayjs from "dayjs";
import { disableFutureDates, replaceEmpty } from "../../generalFunctions";

function MissedVisitReport() {
  const columns = [
    {
      title: "RA Name",
      dataIndex: "raName",
    },
    {
      title: "Site Id",
      dataIndex: "siteId",
    },
    {
      title: "Missed Visits",
      dataIndex: "missedVisit",
    },
  ];

  const [responseData, getRequest] = useApi(
    "/admin/reports/missed-visits",
    null,
    API_METHODS.POST
  );
  const [tableData, setTableData] = useState();
  const [form] = Form.useForm();
  let reportHeaders = [["Site Id", "RA Name", "Missed Visits"]];

  useEffect(() => {
    let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    if (data?.length > 0) {
      data = replaceEmpty(data);
    }
    setTableData(data);
  }, [responseData]);

  const getReportData = () => {
    form
      .validateFields()
      .then((values) => {
        values.date = dayjs(values.date).format("YYYY-MM-DD");
        getRequest(values);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Card title="Missed Visit Report">
      <Form form={form}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="date"
              label={<strong>For Date</strong>}
              rules={[{ required: true }]}
            >
              <DatePicker
                allowClear
                format={"DD-MM-YYYY"}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Space>
              <Button type="primary" onClick={getReportData}>
                Search
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form.resetFields();
                }}
              >
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        clientSideRender={true}
        showDownloadOption={true}
        reportHeaders={reportHeaders}
        reportData={tableData}
        exportFileName={
          "Missed Visit Report-" +
          form.getFieldValue("date")?.format(DEFAULT_DATE_FORMAT)
        }
      />
    </Card>
  );
}

export default MissedVisitReport;
