import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Input, InputNumber, List, Radio, Row, Select, Space, message } from 'antd';
import { API_METHODS, BLANK_VALUE } from '../../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../../hooks/useApi';
import { getClientConfig, isEmpty } from '../../../generalFunctions';
import PrintTakeoverForm from '../mis/TakeoverForm/PrintTakeoverForm';

const UpdateTakeoverForm = () => {
    const [form] = Form.useForm();
    const { site_types } = getClientConfig();
    const navigate = useNavigate();
    const [siteVisitDetails, setSiteVisitDetails] = useState();
    const [dummyState, setDummyState] = useState();
    const { takeoverFormId } = useParams();
    const [responseData, getRequest] = useApi(
        `/admin/takeover-form/${takeoverFormId}`,
        null,
        API_METHODS.GET
    );
    const [updateResponseData, updateRequest] = useApi(
        `/admin/takeover-form/${takeoverFormId}`,
        null,
        API_METHODS.PUT,
    );

    const updateTakeoverForm = () => {
        form.validateFields().then((values) => {
            let newValues = { ...values };
            let newAnswers = [];
            let extra_assets = [];

            siteVisitDetails.questions.forEach(item => {
                let answerData = {
                    "quantity": !isEmpty(values[item.questionId + "quantity"]) ? values[item.questionId + "quantity"] : undefined,
                    "remarks": !isEmpty(values[item.questionId + "remarks"]) ? values[item.questionId + "remarks"] : undefined,
                    "questionId": item.questionId
                }
                delete newValues[item.questionId + "quantity"];
                delete newValues[item.questionId + "remarks"];

                let subAnswerKey = Object.keys(values).filter(key => (key === item.questionId + "sub1" || key === item.questionId + "sub2"));
                if (subAnswerKey.length > 0) {
                    answerData.answers = [];
                    subAnswerKey.forEach(key => {
                        const sub = {
                            id: key.toString()[key.length - 1],
                            ans: values[key]
                        }
                        answerData.answers.push(sub);
                        delete newValues[key];
                    })
                }
                newAnswers.push(answerData);
            });

            siteVisitDetails.extra_asset?.forEach(item => {
                const answer1 = {
                    "id": item.answers[0].id,
                    "ans": values[item.id + "extra_asset" + item.answers[0].id]
                }
                const answer2 = {
                    "id": item.answers[1].id,
                    "ans": values[item.id + "extra_asset" + item.answers[1].id]
                }

                const extra_asset = {
                    id: item.id,
                    answers: [answer1, answer2],
                    remarks: !isEmpty(values[item.id + "extra_asset_remarks"]) ? values[item.id + "extra_asset_remarks"] : undefined,
                    assestname: item.assestname
                }

                delete newValues[item.id + "extra_asset" + item.answers[0].id];
                delete newValues[item.id + "extra_asset" + item.answers[1].id];
                delete newValues[item.id + "extra_asset_remarks"];

                extra_assets.push(extra_asset);
            });

            newValues.answers = newAnswers;
            newValues.extra_asset = extra_assets;
            updateRequest(newValues);
        }).catch(e => console.log(e));
    }

    const handleAnswerChange = (e) => {
        setDummyState(e);
    }

    useEffect(() => {
        if (!responseData.isLoading) {
            let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
            if (data) {
                let initialValues = {};
                let currentQuestionAnswers = [];
                let mainQuestions = [];
                const { takeover_date, site_id, address, site_type, previous_ra_agency, new_ra_agency, remarks,
                    previous_service_provider_name, previous_employee_name, previous_employee_designation, previous_employee_id,
                    new_service_provider_name, new_employee_name, new_employee_designation, new_employee_id, new_employee_signature } = data[0];
                data[0].questions.forEach(q => {
                    let questionWithAnswer = { ...q };
                    let answer = data[0].answers.find(a => a.questionId == questionWithAnswer.questionId);
                    questionWithAnswer.quantity = answer.quantity;
                    questionWithAnswer.remarks = answer.remarks;
                    questionWithAnswer.showrRemarks = q.remarks;
                    questionWithAnswer.showQuantity = q.quantity;
                    currentQuestionAnswers.push(questionWithAnswer);

                    if (questionWithAnswer.subQuestion?.length > 0) {
                        questionWithAnswer.subQuestion.forEach(q => {
                            let questionWithAnswer = { ...q };
                            let sqanswer = answer.answers?.find(a => a.id == questionWithAnswer.id);
                            if (sqanswer) {
                                questionWithAnswer.questionId = answer.questionId + "sub" + questionWithAnswer.id;
                                questionWithAnswer.answerId = sqanswer.ans;
                                currentQuestionAnswers.push(questionWithAnswer);
                            }
                        })
                    }
                });
                mainQuestions = currentQuestionAnswers.filter(q => !q.questionId.toString().includes("sub"));
                data[0].currentQuestionAnswers = [...mainQuestions];
                setSiteVisitDetails(data[0]);

                currentQuestionAnswers.forEach((question) => {
                    let initialAnswer;
                    if (question.answerId) {
                        initialAnswer = {
                            [question.questionId]: parseInt(question.answerId)
                        }
                    } else {
                        initialAnswer = {
                            [`${question.questionId}quantity`]: !isEmpty(question.quantity) ? parseInt(question.quantity) : undefined,
                            [`${question.questionId}remarks`]: question.remarks
                        }
                    }
                    initialValues = { ...initialValues, ...initialAnswer };
                });

                let extra_assets = {};
                data[0].extra_asset?.forEach(item => {
                    const extra_asset = {
                        [item.id + "extra_asset_remarks"]: item.remarks,
                        [item.id + "extra_asset" + item.answers[0].id]: item.answers[0].ans,
                        [item.id + "extra_asset" + item.answers[1].id]: item.answers[1].ans,
                    }
                    extra_assets = { ...extra_assets, ...extra_asset };
                });

                initialValues = { ...initialValues, ...extra_assets };

                initialValues = {
                    ...initialValues, ...{
                        takeover_date, site_id, address, site_type, previous_ra_agency, new_ra_agency, remarks,
                        previous_service_provider_name, previous_employee_name, previous_employee_designation, previous_employee_id,
                        new_service_provider_name, new_employee_name, new_employee_designation, new_employee_id, new_employee_signature
                    }
                };
                form.setFieldsValue(initialValues);
            }
        }
    }, [responseData]);

    useEffect(() => {
        if (updateResponseData.data && !updateResponseData.isLoading && isEmpty(updateResponseData.error)) {
            message.info("Site takeover form updated successfully.");
            navigate(-1);
        } else if (updateResponseData.error && !updateResponseData.isLoading) {
            message.info(updateResponseData.error);
        }

    }, [updateResponseData]);

    useEffect(() => {
        getRequest();
    }, []);

    return (
        <Card loading={responseData.isLoading} title={"Takeover Form - " + siteVisitDetails?.site_id + " by " + (siteVisitDetails?.filled_by_name.toUpperCase()) + " @ " + siteVisitDetails?.submit_timestamp}
            extra={siteVisitDetails && <PrintTakeoverForm siteDetails={siteVisitDetails} />}>
            {siteVisitDetails?.currentQuestionAnswers?.length > 0 &&
                <Form form={form} layout='vertical'>
                    <Space direction='vertical' style={{ width: "100%" }}>
                        <Card title="Site Details">
                            <Row gutter={48}>
                                <Col span={6}>
                                    <Form.Item label="Takeover Date" name="takeover_date">
                                        <Input placeholder='Date' />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Site Id" name="site_id">
                                        <Input placeholder='Site Id' disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Previous RA Agency" name="previous_ra_agency">
                                        <Input placeholder='Previous RA Agency' />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="New  RA Agency" name="new_ra_agency">
                                        <Input placeholder='New  RA Agency' />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Site Type" name="site_type">
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={9}>
                                    <Form.Item label="Address" name="address">
                                        <Input.TextArea rows={3} placeholder='address' disabled />
                                    </Form.Item>
                                </Col>

                                <Col span={9}>
                                    <Form.Item label="Remarks" name="remarks">
                                        <Input.TextArea rows={3} placeholder='Remarks' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <List
                            grid={{
                                gutter: 16,
                                column: 1
                            }}
                            dataSource={siteVisitDetails.currentQuestionAnswers}
                            renderItem={(item, index) => (
                                <List.Item key={index}>
                                    <Card title={(index + 1) + ". " + item.questionHeading}>
                                        <Row>
                                            {item.subQuestion?.map((subQuestion, index) =>
                                                <Col span={24} style={{ marginTop: 10 }}>
                                                    <Card title={(index + 1) + ". " + subQuestion.heading}>
                                                        <Form.Item label="" name={item.questionId + "sub" + subQuestion.id} rules={[{
                                                            required: true
                                                        }]}>
                                                            <Radio.Group key={index} onChange={handleAnswerChange}>
                                                                <Space>
                                                                    {subQuestion.options.map(option =>
                                                                        <Radio value={option.id}>{option.tittle}</Radio>
                                                                    )}
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            {item.showQuantity && <Col span={6}>
                                                <Form.Item label="Quantity" name={item.questionId + "quantity"} style={{ marginTop: 10 }}>
                                                    <InputNumber placeholder='Quantity' />
                                                </Form.Item>
                                            </Col>}
                                            {item.showrRemarks && <Col span={24}>
                                                <Form.Item label="Remarks" name={item.questionId + "remarks"} style={{ marginTop: 10 }}>
                                                    <Input.TextArea rows={3} placeholder='Remark' />
                                                </Form.Item>
                                            </Col>}
                                        </Row>
                                    </Card>
                                </List.Item>

                            )}
                        />
                        <List
                            grid={{
                                gutter: 16,
                                column: 1
                            }}
                            dataSource={siteVisitDetails.extra_asset}
                            renderItem={(item, index) => (
                                <List.Item key={index}>
                                    <Card title={(index + 1) + ". " + item.assestname + " ( Extra Asset )"}>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 10 }}>
                                                <Card title={"Is Available?"}>
                                                    <Form.Item label="" name={item.id + "extra_asset" + item.answers[0].id} rules={[{
                                                        required: true
                                                    }]}>
                                                        <Radio.Group key={index} onChange={handleAnswerChange}>
                                                            <Space>
                                                                {[{ id: 1, tittle: "Yes" }, { id: 2, tittle: "No" }].map(option =>
                                                                    <Radio value={option.id}>{option.tittle}</Radio>
                                                                )}
                                                            </Space>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Card>
                                            </Col>
                                            <Col span={24} style={{ marginTop: 10 }}>
                                                <Card title={"Is Working?"}>
                                                    <Form.Item label="" name={item.id + "extra_asset" + item.answers[1].id} rules={[{
                                                        required: true
                                                    }]}>
                                                        <Radio.Group key={index} onChange={handleAnswerChange}>
                                                            <Space>
                                                                {[{ id: 1, tittle: "Yes" }, { id: 2, tittle: "No" }].map(option =>
                                                                    <Radio value={option.id}>{option.tittle}</Radio>
                                                                )}
                                                            </Space>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="Remarks" name={item.id + "extra_asset_remarks"} style={{ marginTop: 10 }}>
                                                    <Input.TextArea rows={3} placeholder='Remark' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>

                            )}
                        />
                        <Row>
                            <Col span={24}>
                                <Card title={"Previous Service Provider Details"}>
                                    <Row gutter={48}>
                                        <Col span={12}>
                                            <Form.Item label="Service Provider Name" name="previous_service_provider_name">
                                                <Input placeholder='Service Provider Name' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Name" name="previous_employee_name">
                                                <Input placeholder='Emp. Name' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Designation" name="previous_employee_designation">
                                                <Input placeholder='Emp. Designation' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Id" name="previous_employee_id">
                                                <Input placeholder='Emp. Id' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Signature" name="previous_employee_signature">
                                                <Image
                                                    width={200}
                                                    height={75}
                                                    src={siteVisitDetails?.previous_employee_signature}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Selfie" name="previous_employee_selfie">
                                                <Image
                                                    width={150}
                                                    height={200}
                                                    src={siteVisitDetails?.previous_employee_selfie}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Card title={"New Service Provider Details"}>
                                    <Row gutter={48}>
                                        <Col span={12}>
                                            <Form.Item label="Service Provider Name" name="new_service_provider_name">
                                                <Input placeholder='Service Provider Name' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Name" name="new_employee_name">
                                                <Input placeholder='Emp. Name' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Designation" name="new_employee_designation">
                                                <Input placeholder='Emp. Designation' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Emp. Id" name="new_employee_id">
                                                <Input placeholder='Emp. Id' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Signature" name="new_employee_signature">
                                                <Image
                                                    width={200}
                                                    height={75}
                                                    src={siteVisitDetails?.new_employee_signature}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Selfie" name="new_employee_selfie">
                                                <Image
                                                    width={150}
                                                    height={200}
                                                    src={siteVisitDetails?.new_employee_selfie}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Space>
                </Form>
            }
            <Space style={{ float: "right" }}>
                <Button
                    type="default"
                    onClick={() => navigate(-1)}
                    disabled={updateResponseData.isLoading}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={updateTakeoverForm}
                    loading={updateResponseData.isLoading}
                >
                    Update
                </Button>
            </Space>
        </Card >
    );
}

export default UpdateTakeoverForm;