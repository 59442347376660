import React, { useEffect } from "react";
import { setDataToLocalStorage } from "../generalFunctions";
import { API_METHODS } from "../constants";
import useApi from "../hooks/useApi";
import { Card } from "antd";

function MISDashboard() {
  return <Card style={{ height: 250, textAlign: "center", alignContent: "center" }}>
    <h2>Coming Soon</h2>
  </Card>
}

export default MISDashboard;
