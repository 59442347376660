import { Button, Card, Col, DatePicker, Form, Row, Select, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import TableComponent from "../../common/table/TableComponent";
import { useNavigate } from "react-router-dom";
import {  isEmpty,getDataFromLocalStorage, setDataToLocalStorage, disableFutureDates} from "../../../generalFunctions";
import dayjs from "dayjs";

function QRTFormTable() {
    const navigate = useNavigate();
    const columns = [
        {
            title: "Site Id",
            dataIndex: "siteId",
        },
        {
            title: "Filled By",
            dataIndex: "filledByDisplayName",
        },
        {
            title: "Submitted At",
            dataIndex: "submitTimestamp",
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <>
                    <Button size="small" onClick={() => navigate(`${record.id}`)}>
                        View Details
                    </Button>
                </>
            ),
        },
    ];

    const [responseData, getRequest] = useApi(
        `/mis/qrt-form?pageNumber=${1}`,
        null,
        API_METHODS.GET
    );

    const [tableData, setTableData] = useState();
    const [form] = Form.useForm();
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [siteList, setSiteList] = useState();
    const [sites, getSites] = useApi(
        `/mis/qrt-form/sites`,
        null,
        API_METHODS.GET
    );
    
    const [raList, setRAList] = useState();
    const [ra, getRA] = useApi(`/mis/common/ra`, null, API_METHODS.GET);


    useEffect(() => {
        if (sites.data && !sites.isLoading && isEmpty(sites.error)) {
            setSiteList(sites.data);
        } else if (sites.error && !sites.isLoading) {
            message.info(sites.error);
        }
    }, [sites]);

    
  useEffect(() => {
    if (ra.data && !ra.isLoading && isEmpty(ra.error)) {
      setRAList(ra.data);
    } else if (ra.error && !ra.isLoading) {
      message.info(ra.error);
    }
  }, [ra]);

    useEffect(() => {
        getRA();
        getSites(); 
        let filters = getDataFromLocalStorage("MISQRTForm");
        let url = `/mis/qrt-form?pageNumber=${1}`;
        if (filters) {
          if (filters.date) {
            filters.date = dayjs(filters.date);
          }
          form.setFieldsValue(filters);
          url = filters.raId ? url + `&filledBy=${filters.raId}` : url;
          url = filters.siteId ? url + `&dbTableSiteId=${filters.siteId}` : url;
          url = filters.date
            ? url + `&date=${dayjs(filters.date).format("YYYY-MM-DD")}`
            : url;
        }
        getRequest(null, API_METHODS.GET, url);
    }, []);

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        const pagination =
            responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
        setTableData(data);
        setTotalRecords(pagination ? pagination.count : 0);
    }, [responseData]);

    const onPaginationChange = (page) => {
        let url = `/mis/qrt-form?pageNumber=${page.current}`;
        const values = form.getFieldsValue();
        url = values.raId ? url + `&filledBy=${values.raId}` : url;
        url = values.siteId ? url + `&dbTableSiteId=${values.siteId}` : url;
        url = values.date
           ? url + `&date=${dayjs(values.date).format("YYYY-MM-DD")}`
           : url;
        getRequest(null, API_METHODS.GET, url);
        setCurrentPage(page.current);
    };

    const filterResults = () => {
        const values = form.getFieldsValue();
        let url = `/mis/qrt-form?pageNumber=${1}`;
        url = values.raId ? url + `&filledBy=${values.raId}` : url;
        url = values.siteId ? url + `&dbTableSiteId=${values.siteId}` : url;
        url = values.date
          ? url + `&date=${dayjs(values.date).format("YYYY-MM-DD")}`
          : url;
        setDataToLocalStorage("MISQRTForm", values);
        getRequest(null, API_METHODS.GET, url);
        setCurrentPage(1);
    }
    return (
        <Card title="QRT Forms"
            extra={<Button type="default" onClick={() => { navigate("/portal/mis/site/qrt-forms/add") }}>Add New Form</Button>}>
            <Form form={form}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="raId"
                            label={<strong>Roaming  Attendants</strong>}
                        >
                            <Select
                                placeholder="Select Roaming  Attendant"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                            >
                                {raList?.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.displayName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="siteId"
                            label={<strong>Sites</strong>}
                        >
                            <Select
                                placeholder="Select Site"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                            >
                                {siteList?.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.site_id}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>                    
                    <Col span={6}>
                        <Form.Item
                            name="date"
                            label={<strong>Date</strong>}
                           
                        >
                            <DatePicker
                                allowClear
                                format={"DD-MM-YYYY"}
                                disabledDate={disableFutureDates}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space>
                            <Button type="primary" onClick={filterResults}>Search</Button>
                            <Button type="primary" onClick={() => { form.resetFields(); getRequest(); localStorage.removeItem("MISQRTForm");}}>Reset</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <TableComponent
                columns={columns}
                tableData={tableData}
                loading={responseData.isLoading}
                onPaginationChange={onPaginationChange}
                total={totalRecords}
                current={currentPage}
            />
        </Card>
    );
}

export default QRTFormTable;
