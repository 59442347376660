import { Button, Card, Col, DatePicker, Form, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE, DEFAULT_DATE_FORMAT } from "../../constants";
import TableComponent from "../common/table/TableComponent";
import dayjs from "dayjs";
import { disableFutureDates, replaceEmpty } from "../../generalFunctions";

function MaterialRequirementReport() {
    const [reportHeaders, setReportHeaders] = useState();
    const [columns,setColumns] = useState();
    const [responseData, getRequest] = useApi("/admin/reports/material-requirements", null, API_METHODS.POST);
    const [tableData, setTableData] = useState();
    const [form] = Form.useForm();

    useEffect(() => {
        let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        if (data?.length > 0) {
            data = replaceEmpty(data);
            const columns = Object.keys(data[0]).map(element => { return { title: element.replace('_', ' ').toUpperCase(), dataIndex: element } });
            setColumns(columns);
            let reportHeaders = Object.keys(data[0]);
            reportHeaders = [reportHeaders];
            setReportHeaders(reportHeaders);

        }
        setTableData(data);
    }, [responseData]);


    const getMaterialRequirements = () => {
        form.validateFields().then(values => {
            let requestBody = {};
            requestBody.month = dayjs(values.date).month() + 1;
            requestBody.year = dayjs(values.date).year();
            getRequest(requestBody);
        }).catch((e) => { console.log(e) });
    }


    return (
        <Card title="Material Requirements Report">
            <Form form={form}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            name="date"
                            label={<strong>For Month</strong>}
                            rules={[{ required: true }]}
                        >
                            <DatePicker
                                allowClear
                                picker="month"
                                 disabledDate={disableFutureDates}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space>
                            <Button type="primary" onClick={getMaterialRequirements}>Search</Button>
                            <Button type="primary" onClick={() => { form.resetFields(); }}>Reset</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <TableComponent
                columns={columns}
                tableData={tableData}
                loading={responseData.isLoading}
                clientSideRender={true}
                showDownloadOption={true}
                reportHeaders={reportHeaders}
                reportData = {tableData}
                exportFileName={"MaterialRequirements" + form.getFieldValue("date")?.format("MM-YYYY")}
            />
        </Card>
    );
}

export default MaterialRequirementReport;
