import { Card } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import TableComponent from "../common/table/TableComponent";
import dayjs from "dayjs";
import { replaceEmpty } from "../../generalFunctions";

export default function RemainingVisitReport() {
  const columns = [
    {
      title: "RA Name",
      dataIndex: "displayName",
    },
    {
      title: "Remaining Visits",
      dataIndex: "remainingVisits",
    },
  ];

  const [responseData, getRequest] = useApi(
    "/admin/reports/remaining-visits",
    null,
    API_METHODS.GET
  );

  const [tableData, setTableData] = useState();

  useEffect(() => {
    let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    if (data?.length > 0) {
      data = replaceEmpty(data);
    }
    setTableData(data);
  }, [responseData]);

  let reportHeaders = [["RA Name", "Remaining Visits"]];

  useEffect(() => {
    getRequest();
  }, []);

  return (
    <Card title="Remaining Visits">
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        clientSideRender={true}
        showDownloadOption={true}
        reportHeaders={reportHeaders}
        reportData={tableData}
        exportFileName={"Remaining Visits-" + dayjs().format("DD-MM-YYYY")}
      />
    </Card>
  );
}
