import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  message,
  Space,
  Button,
  Descriptions,
  Input,
  InputNumber,
} from "antd";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import useApi from "../../hooks/useApi";
import { isEmpty } from "../../generalFunctions";
import TableComponent from "../common/table/TableComponent";

function ViewMaterialRequirement(props) {
  const { visible, close, selectedRecord } = props;
  const [tableData, setTableData] = useState();
  const [materialRequirements, setMaterialRequirements] = useState([]);

  const [saveMaterialRequestResponce, saveMaterialRequest] = useApi(
    `/admin/material-requirement/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.POST
  );

  useEffect(() => {
    if (saveMaterialRequestResponce.data) {
      close(true);
      message.success("Requirement saved successfully.");
    }
  }, [saveMaterialRequestResponce]);

  const handleCancel = () => {
    close();
  };
  const handleChange = (value, text, record) => {
    let approvedRequirements = materialRequirements;
    const updatedObject = approvedRequirements.find(
      (requirement) => requirement.id == record.id
    );
    if (updatedObject) {
      updatedObject.approvedQuantity = value;
    }
    setMaterialRequirements(approvedRequirements);
  };

  const columns = [
    {
      title: "Material Name",
      dataIndex: "materialName",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Approved Quantity",
      dataIndex: "approvedQuantity",
      render: (text, record) => (
        <InputNumber
          onChange={(value) => handleChange(value, text, record)}
          max={record.quantity}
          defaultValue={0}
          value={record.approvedQuantity}
        ></InputNumber>
      ),
    },
  ];

  useEffect(() => {
    setTableData(selectedRecord.materials);
    let materials = selectedRecord.materials.map((obj) => ({
      approvedQuantity: obj.approvedQuantity,
      id: obj.id,
      material_id: obj.material_id,
      quantity: obj.quantity,
    }));

    setMaterialRequirements(materials);
  }, []);

  const submit = () => {
    saveMaterialRequest({ materials: materialRequirements });
  };
  const items = [
    {
      label: "Supervisor",
      children: selectedRecord.supervisor,
    },
    {
      label: "RA",
      children: selectedRecord.ra,
    },
    {
      label: "Requirement Raised On",
      children: selectedRecord.requirementRaisedAt,
    },
    !isEmpty(selectedRecord.fulfilledRejectedOn) && {
      label: "Fulfilled OR Rejected On",
      children: !isEmpty(selectedRecord.fulfilledRejectedOn)
        ? selectedRecord.fulfilledRejectedOn
        : BLANK_VALUE,
    },
  ];

  return (
    <Modal
      title="Material Requirement Details"
      open={visible}
      onCancel={handleCancel}
      okText={"Save"}
      onOk={submit}
      width={1000}
      cancelText="Close"
    >
      <Descriptions layout="vertical" items={items} />
      <TableComponent columns={columns} tableData={tableData} />
    </Modal>
  );
}

export default ViewMaterialRequirement;
