import { Card } from 'antd';
import React from 'react';

const PrivacyPolicy = (props) => {
    return (
        <Card>
            <Card title="Privacy Policy">
                <div>
                    <h4>This Privacy Policy was last modified on May 15, 2024.
                        As you review our policy, keep in mind that it applies to Pflege India("we", "our", or "us") websites, mobile
                        apps.
                    </h4>

                    <h3>1. Introduction</h3>
                    <p>
                        This Privacy Policy describes how Pflege India("we", "our", or "us") collects, uses, and discloses your
                        information
                        in connection with your use of our mobile application, pflege India ("App").
                    </p>

                    <h3>2. Information We Collect</h3>
                    <p>We collect the following information when you use the App:
                    </p>
                    <p>Location Information: We collect your location information with your consent to provide features within the App.
                        You can choose to deny location access, but this may limit the functionality of the App.</p>
                    <p>
                        Camera Access: We request camera access with your consent to allow you to use specific features within the App
                        (e.g., capturing photos). You can choose to deny camera access, but this may limit the functionality of the App.
                    </p>

                    <h3>3. Use of Information</h3>
                    <p>
                        We use the information we collect for the following purposes:
                    </p>
                    <p>To operate and improve the App.<br />To provide you with features and functionality of the App.
                    </p>

                    <h3>4. Information Sharing</h3>
                    <p>We will not share your information with any third parties except in the following cases:
                    </p>
                    <p>Legal Requirements: We may disclose your information if required by law or to comply with a legal process.</p>

                    <h3>5. Security</h3>
                    <p>
                        We take reasonable steps to secure your information from unauthorized access, disclosure, alteration, or
                        destruction. However, no internet transmission or electronic storage is completely secure.
                    </p>

                    <h3>
                        6. Internal Use Only
                    </h3>
                    <p>This App is intended for internal use by the pflege india organization only. You must be authorized by pflege
                        india
                        to use the App.
                    </p>

                    <h3>7. Changes to this Privacy Policy </h3>
                    <p>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
                        Policy on the Website.
                    </p>

                    <h3>8. Contact Us</h3>
                    <p>If you have any questions about this Privacy Policy, please contact us at aishchandorkar0621@gmail.com.</p>
                </div>
            </Card>
        </Card>
    );
}

export default PrivacyPolicy;